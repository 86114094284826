import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import "../style/QueryForm.css";
import constant from "../assets/constants.json";
import { post_query_form } from "../services/query";
import { useSelector } from "react-redux";

function QueryForm() {
  const [isBusinessFormActive, setBusinessFormActive] = useState(false);
  const [businessFormValidated, setBusinessFormValidated] = useState(false);
  const [serviceFormValidated, setserviceFormValidated] = useState(false);

  const handleBusinessFormSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setBusinessFormValidated(true);
    // follow this format for passing business query
    // const query = `[BUSINESS]::<SUBJECT:${form.formTopic.value}>::${form.formContent.value}`;

    await post_query_form(
      "bussiness",
      form.formTopic.value,
      form.formName.value,
      form.formEmail.value,
      form.formContent.value
    );

    setBusinessFormValidated(false);
  };

  const handleServiceFormSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setserviceFormValidated(true);
    // follow this format for passing query
    // const query = `[SERVICE]::<SUBJECT:${form.formTopic.value}>::${form.formContent.value}`;

    await post_query_form(
      "services",
      form.formTopic.value,
      form.formName.value,
      form.formEmail.value,
      form.formContent.value
    );

    setserviceFormValidated(false);
  };

  return (
    <div className="row p-3 justify-content-center">
      <div className="col-12">
        <div className="row mb-3 justify-content-between ">
          <div
            className="col-5 form-toggle-btn"
            onClick={() => setBusinessFormActive(true)}
            style={
              isBusinessFormActive
                ? {
                    boxShadow: "0 0 5px black",
                    backgroundColor: constant.color.primary,
                    color: "white",
                    fontWeight: "600",
                  }
                : { fontWeight: "600", boxShadow: "0 0 5px black" }
            }
          >
            For Business
          </div>
          <div
            className="col-5 form-toggle-btn"
            onClick={() => setBusinessFormActive(false)}
            style={
              isBusinessFormActive
                ? { fontWeight: "600", boxShadow: "0 0 5px black" }
                : {
                    boxShadow: "0 0 5px black",
                    backgroundColor: constant.color.primary,
                    color: "white",
                    fontWeight: "600",
                  }
            }
          >
            For Services
          </div>
        </div>
        {isBusinessFormActive ? (
          <Form
            noValidate
            validated={businessFormValidated}
            onSubmit={handleBusinessFormSubmit}
            id={"business_query_form"}
            className="py-5 px-4"
          >
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formTopic">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter your business related query"
                  defaultValue=""
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formName">
                <Form.Control type="text" placeholder="Name" required />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid name.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formEmail">
                <Form.Control type="email" placeholder="Email" required />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formContent">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Message"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid message
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="g-3">
              <Form.Group as={Col}>
                <Button
                  style={{
                    backgroundColor: constant.color.primary,
                    color: "white",
                  }}
                  className="sbt"
                  type="submit"
                >
                  Submit form
                </Button>
              </Form.Group>
            </Row>
          </Form>
        ) : (
          <Form
            noValidate
            validated={serviceFormValidated}
            onSubmit={handleServiceFormSubmit}
            id={"service_query_form"}
            className="py-5 px-4"
          >
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formTopic">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter your service related query"
                  defaultValue=""
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formName">
                <Form.Control type="text" placeholder="Name" required />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid name.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formEmail">
                <Form.Control type="email" placeholder="Email" required />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formContent">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Message"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid message
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="g-3">
              <Form.Group as={Col}>
                <Button
                  style={{
                    backgroundColor: constant.color.primary,
                    color: "white",
                  }}
                  className="sbt"
                  type="submit"
                >
                  Submit form
                </Button>
              </Form.Group>
            </Row>
          </Form>
        )}
      </div>
    </div>
  );
}

export default QueryForm;
