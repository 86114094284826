import React from "react";
import constant from "../assets/constants.json";

import { FaQuoteRight } from "react-icons/fa";

function TestimonialCard(props) {
  return (
    <div
      className="container"
      style={{
        position: "relative",
        border: "1px solid white",
        backgroundColor: "transparent",
        textAlign: "center",
        color: "white",
        padding: "2rem",
      }}
    >
      <FaQuoteRight
        size={"4vmax"}
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          translate: "-50% -50%",
          color: constant.color.primary,
        }}
      />
      <div className="row justify-content-around align-items-center">
        <div className="col-12 col-md-7 col-lg-7 col-xl-7">
          <div className="w-100 h-100">
            <p
              style={{
                fontFamily: constant.font.heading,
                fontSize: "calc(0.8rem + 0.5vw)",
                fontOpticalSizing: "auto",
                borderLeft: `5px solid ${constant.color.heavy_light}`,
                borderRight: `5px solid ${constant.color.heavy_light}`,
                padding: "0 8px",
              }}
            >
              {props.data.content}
            </p>
            <p
              style={{
                fontFamily: constant.font.sub,
                textAlign: "right",
                margin: "0",
                paddingTop: "0.5rem",
              }}
            >
              <i>{props.data.person}</i>
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 col-lg-3 col-xl-3">
          {props.data.video ? (
            <div className="p-3">
              <div
                className="d-flex justify-content-center align-items-center container"
                style={{
                  aspectRatio: "1/1",
                  overflowX: "hidden",
                }}
              >
                {props.data.video.includes("www") ? (
                  <iframe
                    src={props.data.video}
                    frameBorder="0"
                    allowFullScreen
                    style={{ height: "100%" }}
                    allow="autoplay; loop"
                  ></iframe>
                ) : (
                  <video
                    src={props.data.video}
                    style={{ height: "100%" }}
                    autoPlay
                    loop
                  ></video>
                )}
              </div>
            </div>
          ) : (
            <div className="p-3">
              <div
                className="d-flex justify-content-center align-items-center container"
                style={{
                  aspectRatio: "1/1",
                  overflowX: "hidden",
                  borderRadius: "50%",
                }}
              >
                <img src={props.data.file} height={"100%"} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TestimonialCard;
