import React, { useEffect, useState } from "react";

import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBNavbarNav,
} from "mdb-react-ui-kit";

import { GoThreeBars } from "react-icons/go";
import { useLocation } from "react-router-dom";

import constants from "../assets/constants.json";

import logo from "../assets/sw-brand-logo.png";

// const deepPurple = "#BD5DFA";
// const primeBlue = "#00b8f7";
const primaryColor = constants.color.primary;

function HeaderLink({ path, title, isActive }) {
  const style = {
    color: isActive ? primaryColor : "black",
    // backgroundColor: isActive ? primaryColor : "transparent",
    backgroundColor: "transparent",
    fontWeight: "400",
    height: "3.5rem",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    borderBottom: isActive
      ? "3px solid" + primaryColor
      : "3px solid transparent",
    fontSize: "calc(0.5rem + .5vw)",
  };

  return (
    <MDBNavbarItem style={{ display: "flex", justifyContent: "center" }}>
      <MDBNavbarLink
        // onFocus={() => setActive(true)}
        // onBlur={() => setActive(false)}
        // onMouseEnter={() => setActive(true)}
        // onMouseLeave={() => setActive(false)}
        style={style}
        href={path}
      >
        {title}
      </MDBNavbarLink>
    </MDBNavbarItem>
  );
}

function JoinUsButton({ path }) {
  const [active, setActive] = useState(false);

  const style = {
    display: "flex",
    justifyContent: "center",
    borderRadius: "5px",
    backgroundColor: active ? primaryColor : constants.color.dark,
    // color: active ? "white" : primaryColor,
    // border: `1px solid ${primaryColor}`,
    height: "max-content",
    padding: "4px 12px",
    fontSize: "calc(0.5rem + .5vw)",
  };

  return (
    <MDBNavbarItem
      onFocus={() => setActive(true)}
      onBlur={() => setActive(false)}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      style={style}
    >
      <MDBNavbarLink
        // style={{ color: active ? "white" : primaryColor }}
        style={{ color: "white" }}
        href={path}
      >
        JOIN US
      </MDBNavbarLink>
    </MDBNavbarItem>
  );
}

function Header() {
  const location = useLocation();
  const [url, setUrl] = useState(null);

  const [showNavNoTogglerSecond, setShowNavNoTogglerSecond] = useState(false);

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  return (
    <>
      <MDBNavbar expand="md" light bgColor="light" className="py-0" fixed="top">
        <MDBContainer className="py-0 align-items-center" fluid>
          <MDBNavbarBrand className="p-1" href="/">
            <div
              style={{
                height: "3rem",
                aspectRatio: "1/1",
                borderRadius: "10px",
                overflow: "hidden",
                objectPosition: "center",
                boxShadow: "0 2px 2px 1px #b7b7b7",
              }}
            >
              <img
                src={logo}
                style={{
                  height: "100%",
                  objectFit: "contain",
                  transform: "scale(1.6) translateY(18%)",
                }}
              />
            </div>
            <div className="row align-items-center">
              <p
                className="col"
                style={{
                  fontSize: "calc(1.2rem + .5vw)",
                  fontWeight: "500",
                  fontFamily: constants.font.heading.family,
                  marginBottom: "0",
                  color: constants.color.primary,
                  marginLeft: "6px",
                  lineHeight: "0.8rem",
                  textAlign: "center",
                  marginTop: "6px",
                }}
              >
                SalonWallah
                <br />
                <span
                  style={{
                    fontSize: "calc(0.5rem + .1vw)",
                    fontWeight: "500",
                    marginBottom: "0",
                    color: constants.color.primary,
                    textTransform: "uppercase",
                    textAlign: "center",
                  }}
                >
                  Salon, Spa, Parlour
                </span>
              </p>
            </div>
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type="button"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowNavNoTogglerSecond(!showNavNoTogglerSecond)}
          >
            <GoThreeBars />
          </MDBNavbarToggler>
          <MDBCollapse navbar show={showNavNoTogglerSecond} className="pb-xs-2">
            <MDBNavbarNav className="mr-auto mb-2 mb-lg-0 justify-content-end align-items-center text-center text-bold">
              <HeaderLink path={"/"} title={"HOME"} isActive={url === "/"} />
              <HeaderLink
                path={"/about"}
                title={"ABOUT"}
                isActive={url === "/about"}
              />
              <HeaderLink
                path={"/gallery"}
                title={"GALLERY"}
                isActive={url === "/gallery"}
              />
              <HeaderLink
                path={"/contact"}
                title={"CONTACT"}
                isActive={url === "/contact"}
              />
              <HeaderLink
                path={"/faq"}
                title={"FAQ"}
                isActive={url === "/faq"}
              />
              <HeaderLink
                path={"/blog"}
                title={"BLOG"}
                isActive={url === "/blog"}
              />
              <div style={{ width: "10px" }}></div>
              <JoinUsButton path={"/contact/#contact-form"} />
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
}

export default Header;
