import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const blogSlice = createSlice({
  name: "blog",
  initialState: { value: initialState },
  reducers: {
    setBlogs: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setBlogs } = blogSlice.actions;

export default blogSlice.reducer;
