import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const testimonialSlice = createSlice({
  name: "testimonial",
  initialState: { value: initialState },
  reducers: {
    setTestimonialList: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setTestimonialList } = testimonialSlice.actions;

export default testimonialSlice.reducer;
