import React from "react";

import cnt from "../assets/constants.json";
import logo from "../assets/sw-brand-logo.png";
import { Accordion } from "react-bootstrap";

import "../style/FaqPage.css";

const faqData = [
  {
    question: "What is Salon Wallah?",
    answer:
      "Salon Wallah is a platform that connects individuals with salons, parlors, and spas in their vicinity. It offers the convenience of booking beauty and wellness services at nearby locations or at home.",
  },
  {
    question: "How does Salon Wallah work?",
    answer:
      "You can browse and select beauty services from our partner salons, parlors, and spas. Choose your preferred location and time for the appointment, or opt for at-home services. Then, simply book your appointment through our platform.",
  },
  {
    question: "Is Salon Wallah available in my area?",
    answer:
      "Salon Wallah is continuously expanding its network. You can check the availability of our services in your area by entering your location on our website or app.",
  },
  {
    question:
      "Are the beauty professionals on Salon Wallah qualified and experienced?",
    answer:
      "Yes, we partner with licensed and experienced beauty professionals who meet our quality standards. Client safety and satisfaction are our top priorities.",
  },
  {
    question: "Can I book services for specific dates and times?",
    answer:
      "Absolutely! Salon Wallah allows you to schedule appointments for the date and time that suit you best, offering flexibility and convenience.",
  },
  {
    question: "How do I pay for services booked through Salon Wallah?",
    answer:
      "You can pay for your services securely through our platform using various payment methods, including credit/debit cards and digital wallets.",
  },
  {
    question: "Can I reschedule or cancel my appointment?",
    answer:
      "Yes, you can reschedule or cancel appointments through the Salon Wallah platform. However, please check the salon's cancellation policy for any associated fees.",
  },
  {
    question: "Is it safe to have at-home services through Salon Wallah?",
    answer:
      "Your safety is our priority. We enforce strict hygiene and safety guidelines for our partner professionals. They follow all necessary precautions during at-home services.",
  },
  {
    question:
      "How can salons and beauty professionals partner with Salon Wallah?",
    answer:
      "Salon and beauty professionals interested in partnering with us can reach out through our website or app. We provide support and resources to onboard new partners.",
  },
  {
    question:
      "Is there a loyalty program or rewards system for Salon Wallah users?  ",
    answer:
      "Yes, Salon Wallah offers a loyalty program for frequent users. You can earn rewards, discounts, and special offers by booking services through our platform regularly.",
  },
];

function FaqPage() {
  const headingStyle = { fontWeight: "bold", textAlign: "start" };

  return (
    <div
      style={{
        marginTop: "3.5rem",
        textAlign: "center",
        backgroundColor: cnt.color.light,
        fontFamily: cnt.font.heading.family,
        color: cnt.color.dark,
      }}
    >
      <section className="container py-md-5">
        <div className="row justify-content-center">
          <div
            className="container row align-items-center text-white"
            style={{
              padding: "calc(1.8rem + .5vw)",
              backgroundColor: cnt.color.primary,
            }}
          >
            <div className="d-none d-md-block col-md-3 col-lg-2 justify-content-center">
              <div
                style={{
                  height: "100%",
                  aspectRatio: "1/1",
                  borderRadius: "10px",
                  overflow: "hidden",
                  objectPosition: "center",
                  boxShadow: "0 2px 2px 1px #b7b7b733",
                }}
              >
                <img
                  src={logo}
                  style={{
                    height: "100%",
                    objectFit: "contain",
                    transform: "scale(1.6) translateY(18%)",
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-md-9 col-lg-10">
              <h1
                style={{
                  //fontSize: "calc(0.5rem + 2vw)",
                  margin: 0,
                  fontWeight: "bold",
                  textAlign: "start",
                }}
                is="h2"
              >
                Frequently Asked Questions
              </h1>
              <p
                style={{
                  fontSize: "calc(.5rem + .5vw)",
                  margin: 0,
                  textAlign: "start",
                }}
                is="h6"
              >
                Here are a few of the questions we get the most. If you don't
                see what's on your mind, reach out to us anytime on phone, chat,
                or email.
              </p>
            </div>
          </div>
        </div>

        <div className="container my-5">
          <Accordion style={{ borderRadius: 0 }}>
            {faqData.map((item, id) => (
              <Accordion.Item key={id} eventKey={id} className="faq-item">
                <Accordion.Header>{item.question}</Accordion.Header>
                <Accordion.Body className="faq-answer">
                  {item.answer}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </section>
    </div>
  );
}

export default FaqPage;
