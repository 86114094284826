import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: "",
  refreshToken: "",
};

export const tokenSlice = createSlice({
  name: "token",
  initialState: { value: initialState },
  reducers: {
    setTokens: (state, action) => {
      state.value.accessToken = action.payload.accessToken;
      state.value.refreshToken = action.payload.refreshToken;
    },
  },
});

export const { setTokens } = tokenSlice.actions;

export default tokenSlice.reducer;
