import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";

import { Routes, Route } from "react-router-dom";

import UnderConstruction from "./components/UnderConstruction";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Homepage from "./pages/Homepage";
import BlogPage from "./pages/BlogPage";
import GalleryPage from "./pages/GalleryPage";
import ContactPage from "./pages/ContactPage";
import AboutPage from "./pages/AboutPage";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";
import FaqPage from "./pages/FaqPage";
import BenefitsPage from "./pages/BenefitsPage";
import Sitemap from "./pages/Sitemap";
import {
  admin_get_blogs_list,
  admin_get_gallery_list,
  admin_get_services_list,
  admin_get_testimonials_list,
  admin_get_tokens,
} from "./services/admin";
import { setTokens } from "./redux/features/tokenSlice";
import { setServicesList } from "./redux/features/servicesSlice";
import { setBlogs } from "./redux/features/blogSlice";
import { setGalleryImages } from "./redux/features/gallerySlice";
import { setTestimonialList } from "./redux/features/testimonialSlice";

export default function App() {
  const dispatch = useDispatch();

  async function initialize() {
    // all initial data value calls here
    await admin_get_tokens()
      .then((res) => {
        dispatch(
          setTokens({
            accessToken: res.data.accessToken,
            refreshToken: res.data.refreshToken,
          })
        );

        return res.data.accessToken;
      })
      .then(async (accessToken) => {
        await admin_get_services_list(accessToken)
          .then((data) => {
            dispatch(setServicesList(data.rows));
          })
          .catch((err) => console.log(err));

        return accessToken;
      })
      .then(async (accessToken) => {
        await admin_get_blogs_list(accessToken)
          .then((data) => {
            dispatch(setBlogs(data.rows));
          })
          .catch((err) => console.log(err));
      })
      .then(async (accessToken) => {
        await admin_get_gallery_list(accessToken)
          .then((data) => {
            dispatch(setGalleryImages(data.rows));
          })
          .catch((err) => console.log(err));
      })
      .then(async (accessToken) => {
        await admin_get_testimonials_list(accessToken)
          .then((data) => {
            dispatch(setTestimonialList(data.rows));
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));

    // dispatch(setServicesList(servicesList.rows));

    // get all other data services, locations, gallery
    // const [resCityName, resForecast, resAQI] = await Promise.all([
    //   axios.get(
    //     `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${res.coords.latitude}&longitude=${res.coords.longitude}&localityLanguage=en`
    //   ),
    //   axios.get(
    //     `https://api.open-meteo.com/v1/forecast?latitude=${res.coords.latitude}&longitude=${res.coords.longitude}&hourly=temperature_2m,dewpoint_2m,apparent_temperature,pressure_msl,cloudcover,windspeed_10m,winddirection_10m,visibility,precipitation,rain,precipitation_probability&daily=temperature_2m_max,temperature_2m_min,sunrise,uv_index_max,rain_sum,sunset&timezone=auto`
    //   ),
    //   axios.get(
    //     `https://air-quality-api.open-meteo.com/v1/air-quality?latitude=${res.coords.latitude}&longitude=${res.coords.longitude}&timezone=auto&hourly=us_aqi`
    //   ),
    // ]).catch((err) => console.log(err));
  }

  useEffect(() => {
    initialize();
  }, []);

  return (
    <div className="App">
      <Header />

      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/tnc" element={<TermsPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/benefits" element={<BenefitsPage />} />
        <Route path="/sitemap" element={<Sitemap />} />
        <Route path="/under-construction" element={<UnderConstruction />} />
      </Routes>

      <Footer />
    </div>
  );
}
