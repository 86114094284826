import React, { useState } from "react";
import constant from "../assets/constants.json";
import bgImg from "../assets/home-4.webp";
import TestimonialCard from "./TestimonialCard";

import personImg from "../assets/profile.jpg";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import { Carousel } from "react-bootstrap";
import { useSelector } from "react-redux";

let dummyTestimonialVideo = "https://www.youtube.com/embed/ppawFI1u6Po";

const sub_head_txt_above = {
  color: constant.color.primary,
  fontWeight: "400",
  fontFamily: constant.font.heading.family,
};

const section_head = {
  fontWeight: "700",
  fontFamily: constant.font.sub.family,
  margin: "0",
  marginTop: "8px",
  color: "white",
};

const data = [
  {
    person: "Person A",
    img: personImg,
    video: null,
    text: "Sample text which would later be replaced by testimonials given by customers of the salon.",
  },
  {
    person: "Person B",
    img: null,
    video: dummyTestimonialVideo,
    text: "Sample text which would later be replaced by testimonials given by customers of the salon.",
  },
  {
    person: "Person C",
    img: personImg,
    video: null,
    text: "Sample text which would later be replaced by testimonials given by customers of the salon.",
  },
];

function Testimonials() {
  const [index, setIndex] = useState(0);

  const testimonialsList = useSelector((state) => state.testimonial.value);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <section
      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div
        className="py-5 px-1"
        style={{ backgroundColor: "rgba(0,0,0,0.65)" }}
      >
        <h5 style={sub_head_txt_above}>WORD FROM OUR CUSTOMERS</h5>
        <h1 style={section_head}>TESTIMONIALS</h1>
        <div className="container-fluid py-5">
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            controls={false}
          >
            {testimonialsList.map((item, id) => (
              <Carousel.Item
                key={id}
                style={{ padding: "1rem" }}
                interval={3000}
              >
                <TestimonialCard data={item} />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
