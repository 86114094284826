import React from "react";

import cnt from "../assets/constants.json";

function Sitemap() {
  const headingStyle = { fontWeight: "bold", textAlign: "start" };

  return (
    <div
      style={{
        marginTop: "3.5rem",
        textAlign: "center",
        backgroundColor: cnt.color.light,
        fontFamily: cnt.font.heading.family,
        color: cnt.color.dark,
        minHeight: "80vh",
      }}
    >
      <section
        style={{
          padding: "2rem",
          textAlign: "justify",
        }}
      >
        <h3
          style={{
            padding: "1rem",
            backgroundColor: cnt.color.primary,
            color: "white",
            fontWeight: "bold",
            marginBottom: "2rem",
          }}
        >
          Sitemap
        </h3>
        <section className="container">
          <div className="row justify-content-space-around">
            <div className="col-6 col-lg-3 col-xl-3">
              <section>
                <h5 style={headingStyle}>Home</h5>
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/#intro_2">Introduction</a>
                  </li>
                  <li>
                    <a href="/#services">Services</a>
                  </li>
                  <li>
                    <a href="/#service_gallery">Services Gallery</a>
                  </li>
                  <li>
                    <a href="/#partner">Partner With Us</a>
                  </li>
                  <li>
                    <a href="/#locations">Locations</a>
                  </li>
                  <li>
                    <a href="/#testimonials">Testimonials</a>
                  </li>
                </ul>
              </section>

              <section>
                <h5 style={headingStyle}>Social</h5>
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <a href="https://www.facebook.com/people/Salon-Wallah/61551934917322/?is_tour_completed=true">
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/SalonWallah1">Twitter</a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/salonwallah1/">
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/salon-wallah-722638293/">
                      LinkedIn
                    </a>
                  </li>
                </ul>
              </section>
            </div>

            <div className="col-6 col-lg-3 col-xl-3">
              <section>
                <h5 style={headingStyle}>About</h5>
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <a href="/about">About</a>
                  </li>
                  <li>
                    <a href="/about/#intro">Introduction</a>
                  </li>
                  <li>
                    <a href="/about/#gallery">Status</a>
                  </li>
                  <li>
                    <a href="/about/#why_partner">Why Partner?</a>
                  </li>
                </ul>
              </section>

              <section>
                <h5 style={headingStyle}>Blogs</h5>
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <a href="/blog">Blogs</a>
                  </li>
                  <li>
                    <a href="/blog/#whats_happening">Whats Happening?</a>
                  </li>
                  <li>
                    <a href="/blog/#blogs">Blog Section</a>
                  </li>
                </ul>
              </section>

              <section>
                <h5 style={headingStyle}>Sitemap</h5>
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <a href="/sitemap">Sitemap</a>
                  </li>
                </ul>
              </section>
            </div>

            <div className="col-6 col-lg-3 col-xl-3">
              <section>
                <h5 style={headingStyle}>Gallery</h5>
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <a href="/gallery">Gallery</a>
                  </li>
                  <li>
                    <a href="/gallery/#gallery_grid">Gallery Grid</a>
                  </li>
                  <li>
                    <a href="/gallery/#project_imgs">Our Projects</a>
                  </li>
                  <li>
                    <a href="/gallery/#style_imgs">Our Styles</a>
                  </li>
                  <li>
                    <a href="/gallery/#service_imgs">Our Services</a>
                  </li>
                </ul>
              </section>

              <section>
                <h5 style={headingStyle}>Contact</h5>
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                  <li>
                    <a href="/contact/#contact-links">Contact Links</a>
                  </li>
                  <li>
                    <a href="/contact/#contact-form">Query Form</a>
                  </li>
                </ul>
              </section>
            </div>

            <div className="col-6 col-lg-3 col-xl-3">
              <section>
                <h5 style={headingStyle}>Privacy</h5>
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <a href="/privacy">Privacy</a>
                  </li>
                </ul>
              </section>

              <section>
                <h5 style={headingStyle}>Terms & Conditions</h5>
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <a href="/tnc">Terms & Conditions</a>
                  </li>
                </ul>
              </section>

              <section>
                <h5 style={headingStyle}>FAQ</h5>
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <a href="/faq">FAQ</a>
                  </li>
                </ul>
              </section>

              <section>
                <h5 style={headingStyle}>Benefits</h5>
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <a href="/benefits">Benefits</a>
                  </li>
                </ul>
              </section>

              <section>
                <h5 style={headingStyle}>Not Found</h5>
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <a href="/under-construction">404</a>
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}

export default Sitemap;
