import React, { useRef } from "react";

import "../style/GalleryPage.css";

import sampleImg from "../assets/sample-1-min.jpg";
import sampleImg2 from "../assets/sample-2.jpg";
import sampleImgLong from "../assets/img-long-gallery.jpeg";

import blogBgBannerImg from "../assets/blog-bg1.jpg";
import bannerImg from "../assets/about-banner.jpg";
import bannerImg2 from "../assets/banner-gallery.jpg";

// gallery pics
import galleryWallImg1 from "../assets/gallery-1.jpg";
import galleryWallImg2 from "../assets/gallery-2.jpg";
import galleryWallImg3 from "../assets/gallery-3.jpg";
import galleryWallImg4 from "../assets/home-4.webp";
import galleryWallImg5 from "../assets/gallery-5.jpg";

import styleImg1 from "../assets/style-1.jpg";
import styleImg2 from "../assets/style-2.jpg";
import styleImg3 from "../assets/style-3.jpg";
import styleImg4 from "../assets/style-4.jpg";
import styleImg5 from "../assets/style-5.jpg";
import styleImg6 from "../assets/style-6.jpg";
import styleImg7 from "../assets/style-7.jpg";

import serviceImg1 from "../assets/srv-1.jpg";
import serviceImg2 from "../assets/srv-2.jpg";
import serviceImg3 from "../assets/srv-3.jpg";
import serviceImg4 from "../assets/srv-4.jpg";

import { FiHeart } from "react-icons/fi";

import pageBannerVideo from "../assets/videos/man-hair-styling.mp4";
import galleryVid1 from "../assets/videos/nails-color.mp4";

import constant from "../assets/constants.json";
import { NavLink } from "react-bootstrap";
import { useSelector } from "react-redux";

const sub_head_txt_above = {
  color: constant.color.primary,
  fontWeight: "400",
  fontFamily: constant.font.heading.family,
};

const section_head = {
  fontWeight: "700",
  fontFamily: constant.font.sub.family,
  margin: "0",
  marginTop: "8px",
  color: "white",
};

const styleImgs = [
  styleImg1,
  styleImg2,
  styleImg3,
  styleImg4,
  styleImg5,
  styleImg6,
  styleImg7,
];

const projectImgs = [
  styleImg3,
  styleImg4,
  styleImg5,
  styleImg6,
  styleImg7,
  styleImg3,
  styleImg4,
  styleImg5,
  styleImg6,
  styleImg7,
];

const serviceImgs = [serviceImg1, serviceImg2, serviceImg3, serviceImg4];

function GalleryPage() {
  const serviceImagesRef = useRef(null);

  const serviceImages = useSelector((state) => state.services.value).map(
    (item) => item.urls[0]
  );

  const galleryImages = useSelector((state) => state.gallery.value);

  return (
    <div style={{ textAlign: "center", marginTop: "3.5rem" }}>
      <section>
        <div
          style={{
            height: "100vh",
            // background: `url(${bannerImg2})`,
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            backgroundColor: "transparent",
            backgroundSize: "cover",
          }}
        >
          <video muted playsInline autoPlay loop id="bannerVideo">
            <source src={pageBannerVideo} type="video/mp4" />
          </video>
          <div
            style={{
              width: "inherit",
              height: "inherit",
              backdropFilter: "brightness(70%)",
              textAlign: "right",
            }}
            className="row align-content-center"
          >
            <div className="col p-5">
              <h5
                style={{
                  ...sub_head_txt_above,
                  fontSize: "2vmax",
                  color: constant.color.light,
                  textTransform: "uppercase",
                }}
              >
                A Look Into Our Memories
              </h5>
              <h1 style={{ ...section_head, fontSize: "9vmin" }}>GALLERY</h1>
            </div>
          </div>
        </div>
      </section>

      <section
        id="gallery_grid"
        className="py-5"
        style={{ backgroundColor: "white" }}
      >
        <div className="container">
          <div className="grid-gallery-1 w-100">
            <div className="side-info">
              <div
                className="container text-start h-100 p-4"
                style={{ backgroundColor: constant.color.dark }}
              >
                <h1
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    marginTop: "1rem",
                    marginBottom: "4rem",
                  }}
                >
                  Professional, hair and style design
                </h1>
                <p
                  style={{
                    color: constant.color.tertiary,
                    fontSize: "1.3rem",
                    marginBottom: "4rem",
                  }}
                >
                  Article evident arrived express highest men did boy. Mistress
                  sensible entirely am so. Quick can manor smart money hopes
                  worth too. Comfort produce husband boy her had hearing. Law
                  others theirs passed but wishes. You day real less till dear
                  read. Considered use dispatched melancholy sympathize
                  discretion led.{" "}
                </p>
                <button
                  className="primary_btn_style"
                  style={{
                    color: "white",
                    backgroundColor: constant.color.primary,
                  }}
                  onClick={() => {
                    serviceImagesRef.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  See More
                </button>
              </div>
            </div>
            <div className="img1">
              <div
                style={{
                  overflow: "hidden",
                }}
              >
                <img
                  src={galleryWallImg1}
                  loading="lazy"
                  width={"100%"}
                  style={{ objectFit: "cover", aspectRatio: "1/1" }}
                />
              </div>
            </div>
            <div className="img2">
              <div
                style={{
                  overflow: "hidden",
                }}
              >
                <img
                  src={galleryWallImg4}
                  loading="lazy"
                  width={"100%"}
                  style={{ objectFit: "cover", aspectRatio: "1/1" }}
                />
              </div>
            </div>
            <div className="img3-long">
              <div
                style={{
                  overflow: "hidden",
                  aspectRatio: "1/2",
                  objectFit: "cover",
                }}
              >
                <video muted playsInline autoPlay loop width={"100%"}>
                  <source src={galleryVid1} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="img4">
              <div style={{ overflow: "hidden" }}>
                <img
                  src={galleryWallImg3}
                  loading="lazy"
                  width={"100%"}
                  style={{ objectFit: "cover", aspectRatio: "1/1" }}
                />
              </div>
            </div>
            <div className="img5">
              <div style={{ overflow: "hidden" }}>
                <img
                  src={galleryWallImg5}
                  loading="lazy"
                  width={"100%"}
                  style={{ objectFit: "cover", aspectRatio: "1/1" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="py-5 bg-white"></div>

      <section
        id="mid-banner"
        style={{
          backgroundImage: `url(${blogBgBannerImg})`,
          backgroundSize: "cover",
          height: "50vmin",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
        }}
      >
        <div
          style={{ height: "inherit", backdropFilter: "brightness(50%)" }}
          className="container-fluid"
        >
          <div className="row align-items-center h-100">
            <div className="col ">
              <h1
                style={{
                  ...sub_head_txt_above,
                  color: constant.color.light,
                  fontWeight: "600",
                  letterSpacing: "6px",
                }}
              >
                WE MAKE IT HAPPEN
              </h1>
            </div>
          </div>
        </div>
      </section>

      <div className="py-5 bg-white"></div>

      <section
        ref={serviceImagesRef}
        id="service_imgs"
        className="py-5"
        style={{ backgroundColor: "white" }}
      >
        <div className="container">
          <div className="row g-3">
            <div className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <div
                style={{
                  aspectRatio: "1/1",
                  backgroundColor: constant.color.dark,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <div className="container">
                  <p
                    style={{
                      color: "white",
                      fontSize: "4vmax",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Our Services
                  </p>
                </div>
              </div>
            </div>

            {serviceImages.map((img, index) => (
              <div
                key={index}
                className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3"
              >
                <div style={{ aspectRatio: "1/1" }}>
                  <img
                    src={img}
                    loading="lazy"
                    style={{ aspectRatio: "1/1", objectFit: "cover" }}
                    alt="style-img"
                    height={"100%"}
                  />
                </div>
              </div>
            ))}

            <div className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <div
                className="container"
                style={{
                  aspectRatio: "1/1",
                  backgroundColor: constant.color.primary,
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <FiHeart style={{ margin: "0 auto" }} size={"3vmax"} />
                <p
                  style={{
                    fontSize: "3.5vmin",
                    marginBottom: "0.5rem",
                    fontWeight: "bold",
                  }}
                >
                  Our Services
                </p>
                <p
                  style={{
                    fontSize: "2.5vmin",
                    marginBottom: "0.2rem",
                  }}
                >
                  SalonWallah
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="py-5 bg-white"></div>

      <section
        id="mid-banner"
        style={{
          backgroundImage: `url(${galleryWallImg4})`,
          backgroundSize: "cover",
          height: "50vmin",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
        }}
      >
        <div
          style={{ height: "inherit", backdropFilter: "brightness(50%)" }}
          className="container-fluid"
        >
          <div className="row align-items-center h-100">
            <div className="col ">
              <h1
                style={{
                  ...sub_head_txt_above,
                  color: constant.color.light,
                  fontWeight: "600",
                  letterSpacing: "6px",
                }}
              >
                WE CARE FOR OUR CUSTOMERS
              </h1>
            </div>
          </div>
        </div>
      </section>

      <div className="py-5 bg-white"></div>

      <section
        id="project_imgs"
        className="py-5"
        style={{ backgroundColor: "white" }}
      >
        <div className="container">
          <div className="row g-3">
            <div className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <div
                style={{
                  aspectRatio: "1/1",
                  backgroundColor: constant.color.dark,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <div className="container">
                  <p
                    style={{
                      color: "white",
                      fontSize: "4vmax",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Our Gallery
                  </p>
                </div>
              </div>
            </div>

            {galleryImages.map((img, index) => (
              <div
                key={index}
                className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3"
              >
                <div style={{ aspectRatio: "1/1" }}>
                  <img
                    src={img}
                    style={{ aspectRatio: "1/1", objectFit: "cover" }}
                    loading="lazy"
                    alt="style-img"
                    height={"100%"}
                  />
                </div>
              </div>
            ))}

            <div className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <div
                className="container"
                style={{
                  aspectRatio: "1/1",
                  backgroundColor: constant.color.primary,
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <FiHeart style={{ margin: "0 auto" }} size={"3vmax"} />
                <p
                  style={{
                    fontSize: "3.5vmin",
                    marginBottom: "0.5rem",
                    fontWeight: "bold",
                  }}
                >
                  Our Gallery
                </p>
                <p
                  style={{
                    fontSize: "2.5vmin",
                    marginBottom: "0.2rem",
                  }}
                >
                  SalonWallah
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default GalleryPage;
