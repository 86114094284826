import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const getOnlyImagesSrc = (data = []) => {
  let src = [];

  data.forEach((item) => {
    src.push(...item.files);
  });

  return src;
};

export const gallerySlice = createSlice({
  name: "gallery",
  initialState: { value: initialState },
  reducers: {
    setGalleryImages: (state, action) => {
      state.value = getOnlyImagesSrc(action.payload);
    },
  },
});

export const { setGalleryImages } = gallerySlice.actions;

export default gallerySlice.reducer;
