import React from "react";

import cnt from "../assets/constants.json";
import logo from "../assets/sw-brand-logo.png";

function TermsPage() {
  const headingStyle = { fontWeight: "bold", textAlign: "start" };

  return (
    <div
      style={{
        marginTop: "3.5rem",
        backgroundColor: cnt.color.light,
        fontFamily: cnt.font.heading.family,
        color: cnt.color.dark,
      }}
    >
      <section className="container py-md-5">
        <div className="row justify-content-center">
          <div
            className="container row align-items-center text-white"
            style={{
              padding: "calc(1.8rem + .5vw)",
              backgroundColor: cnt.color.primary,
            }}
          >
            <div className="d-none d-md-block col-md-3 col-lg-2 justify-content-center">
              <div
                style={{
                  height: "100%",
                  aspectRatio: "1/1",
                  borderRadius: "10px",
                  overflow: "hidden",
                  objectPosition: "center",
                  boxShadow: "0 2px 2px 1px #b7b7b733",
                }}
              >
                <img
                  src={logo}
                  style={{
                    height: "100%",
                    objectFit: "contain",
                    transform: "scale(1.6) translateY(18%)",
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-md-9 col-lg-10">
              <h1
                style={{
                  //fontSize: "calc(0.5rem + 2vw)",
                  margin: 0,
                  fontWeight: "bold",
                  textAlign: "start",
                }}
                is="h2"
              >
                Terms & Conditions
              </h1>
            </div>
          </div>
        </div>

        <article
          className="container text-start my-5"
          style={{ textAlign: "justify" }}
        >
          <h2 style={headingStyle}>Terms and Conditions for Customers</h2>
          <br />
          <p>
            Welcome to Salon Wallah, the platform that connects you with
            top-quality salon, parlor, and spa services, whether you prefer
            visiting a salon or having services delivered to your home. By using
            our website or mobile application (collectively, "Services"), you
            agree to comply with and be bound by the following Terms &
            Conditions ("Terms"). Please read these Terms carefully before
            accessing or using our Services. If you do not agree with these
            Terms, you may not use our Services.
          </p>
          <br />
          <h4 style={headingStyle}>1. Acceptance of Terms</h4>
          <br />
          <p>
            By accessing or using our Services, you acknowledge that you have
            read, understood, and agree to be bound by these Terms. Salon Wallah
            reserves the right to modify these Terms at any time. It is your
            responsibility to review these Terms periodically for updates. Your
            continued use of our Services after any modifications indicates your
            acceptance of the updated Terms.
          </p>
          <br />
          <h4 style={headingStyle}>2. Use of Services</h4>
          <br />
          <h5 style={headingStyle}>2.1 Eligibility</h5>
          <p>
            By accessing or using our Services, you acknowledge that you have
            read, understood, and agree to be bound by these Terms. Salon Wallah
            reserves the right to modify these Terms at any time. It is your
            responsibility to review these Terms periodically for updates. Your
            continued use of our Services after any modifications indicates your
            acceptance of the updated Terms.
          </p>
          <h5 style={headingStyle}>2.2 User Account</h5>
          <p>
            To access certain features of our Services, you may be required to
            create a user account. You are responsible for maintaining the
            confidentiality of your account credentials, and you agree to notify
            us immediately if you suspect any unauthorized access to your
            account.
          </p>
          <h5 style={headingStyle}>2.3 User Conduct</h5>
          <p>When using our Services, you agree not to:</p>
          <ul>
            <li>
              Violate any applicable laws, regulations, or third-party rights.
            </li>
            <li>
              Use our Services for any fraudulent, illegal, or unauthorized
              purposes.
            </li>
            <li>
              Attempt to gain unauthorized access to our platform or interfere
              with its operation.
            </li>
            <li>
              Use automated scripts or bots to access or interact with our
              platform.
            </li>
            <li>Harass, threaten, or harm other users or service providers.</li>
            <li>
              Submit false information, including fake bookings or payment
              details.
            </li>
          </ul>

          <br />

          <h4 style={headingStyle}>3. Booking and Payments</h4>
          <br />
          <h5 style={headingStyle}>3.1 Booking Process</h5>
          <p>
            When booking services through our platform, you agree to provide
            accurate and complete information, including your contact details,
            service preferences, and payment information. We may pre-authorize
            or charge your payment method to verify its validity and ensure
            availability of funds.
          </p>
          <h5 style={headingStyle}>3.2 Service Fees</h5>
          <p>
            Service prices, including any additional charges or taxes, will be
            displayed before you confirm your booking. You agree to pay the
            total amount specified during the booking process. Prices may vary
            based on location, service type, and other factors.
          </p>
          <h5 style={headingStyle}>3.3 Cancellations and Refunds</h5>
          <p>
            Cancellations and refunds are subject to the cancellation policy of
            the service provider you booked with. Salon Wallah is not
            responsible for refunding any fees or charges unless otherwise
            stated in our Refund Policy.
          </p>
          <h5 style={headingStyle}>3.4 Payment Security</h5>
          <p>
            We take payment security seriously and use industry-standard
            encryption and security measures to protect your financial data. By
            using our payment processing services, you agree to abide by the
            terms and conditions of our payment partners.
          </p>

          <br />

          <h4 style={headingStyle}>4. Salon, Parlor, and Spa Services</h4>
          <br />
          <h5 style={headingStyle}>4.1 Service Providers</h5>
          <p>
            Service providers listed on our platform are independent
            professionals or businesses. We do not employ service providers
            directly but provide a platform to connect you with them. Salon
            Wallah is not responsible for the actions or quality of services
            provided by service providers.
          </p>
          <h5 style={headingStyle}>4.2 Quality Assurance</h5>
          <p>
            While we strive to connect you with reputable service providers, we
            do not guarantee the quality, accuracy, or reliability of their
            services. Your satisfaction and safety are important to us, and we
            encourage you to provide feedback and reviews about your
            experiences.
          </p>
          <h5 style={headingStyle}>4.3 At-Home Services</h5>
          <p>
            If you choose at-home services, please ensure a safe and suitable
            environment for the service provider to perform their duties. We
            reserve the right to cancel or reschedule appointments if conditions
            are deemed unsafe or unsuitable.
          </p>

          <br />

          <h4 style={headingStyle}>5. User Content</h4>
          <br />
          <h5 style={headingStyle}>5.1 Ownership</h5>
          <p>
            Any content, including reviews, feedback, and comments, that you
            submit or post on our platform is your responsibility. You retain
            ownership of your content, but by submitting it to Salon Wallah, you
            grant us a non-exclusive, worldwide, royalty-free license to use,
            display, and distribute your content on our platform and for
            promotional purposes.
          </p>
          <h5 style={headingStyle}>5.2 Prohibited Content</h5>
          <p>
            You may not post content that is defamatory, abusive, obscene, or
            violates any applicable laws or regulations. We reserve the right to
            remove or edit content that violates our guidelines.
          </p>

          <br />

          <h4 style={headingStyle}>6. Intellectual Property</h4>
          <br />
          <h5 style={headingStyle}>6.1 Ownership</h5>
          <p>
            Salon Wallah and its licensors retain all rights, title, and
            interest in and to our Services, including all content, graphics,
            logos, and software. You are granted a limited, non-exclusive, and
            non-transferable license to use our platform for personal,
            non-commercial purposes.
          </p>
          <h5 style={headingStyle}>6.2 Trademarks</h5>
          <p>
            All trademarks, service marks, logos, and trade names displayed on
            our platform are the property of Salon Wallah or their respective
            owners. You may not use these marks without our prior written
            consent.
          </p>

          <br />

          <h4 style={headingStyle}>7. Privacy</h4>
          <br />
          <p>
            Your privacy is important to us. Please review our Privacy Policy to
            understand how we collect, use, and protect your personal
            information when you use our Services.
          </p>

          <br />

          <h4 style={headingStyle}>8. Termination</h4>
          <br />
          <p>
            Salon Wallah reserves the right to suspend or terminate your access
            to our Services at our discretion, without prior notice, if we
            believe you have violated these Terms or engaged in any fraudulent
            or illegal activities.
          </p>

          <br />

          <h4 style={headingStyle}>9. Disclaimers</h4>
          <br />
          <h5 style={headingStyle}>9.1 No Warranty</h5>
          <p>
            Our Services are provided "as is" and "as available." We do not make
            any warranties, express or implied, regarding the quality,
            reliability, or availability of our platform or services.
          </p>
          <h5 style={headingStyle}>9.2 Limitation of Liability</h5>
          <p>
            To the extent permitted by applicable laws, Salon Wallah and its
            affiliates, officers, directors, employees, and agents shall not be
            liable for any indirect, incidental, special, consequential, or
            punitive damages, or any loss of profits or revenues.
          </p>

          <br />

          <h4 style={headingStyle}>10. Governing Law and Dispute Resolution</h4>
          <br />
          <p>
            These Terms are governed by the laws of Govt. of India. Any disputes
            or claims arising out of or in connection with these Terms shall be
            subject to the exclusive jurisdiction of the courts in Govt. of
            India.
          </p>

          <br />

          <h4 style={headingStyle}>11. Contact Us</h4>
          <br />
          <p>
            If you have any questions, concerns, or feedback regarding these
            Terms, please contact us at [Insert Contact Information].
          </p>

          <br />
        </article>

        <article
          className="container text-start my-5"
          style={{ textAlign: "justify" }}
        >
          <h2 style={headingStyle}>Terms and Conditions for Partners</h2>
          <br />
          <p>
            Welcome to Salon Wallah, the platform that connects beauty and
            wellness professionals and businesses with clients seeking
            top-quality services. By becoming a partner with Salon Wallah, you
            agree to comply with and be bound by the following Terms &
            Conditions ("Terms"). Please read these Terms carefully before
            registering as a partner. If you do not agree with these Terms, you
            may not use our platform.
          </p>
          <br />
          <h4 style={headingStyle}>1. Acceptance of Terms</h4>
          <br />
          <p>
            By registering as a partner on our platform, you acknowledge that
            you have read, understood, and agree to be bound by these Terms.
            Salon Wallah reserves the right to modify these Terms at any time.
            It is your responsibility to review these Terms periodically for
            updates. Your continued use of our platform after any modifications
            indicates your acceptance of the updated Terms.
          </p>
          <br />
          <h4 style={headingStyle}>2. Partner Registration</h4>
          <br />
          <h5 style={headingStyle}>2.1 Eligibility</h5>
          <p>
            To become a partner with Salon Wallah, you must meet certain
            eligibility criteria, including but not limited to the following:
          </p>
          <ul>
            <li>
              You must be legally authorized to provide the services you offer.
            </li>
            <li>
              You must comply with all applicable laws, regulations, and
              licensing requirements in your jurisdiction.
            </li>
            <li>
              You must provide accurate and complete information during the
              registration process.
            </li>
          </ul>
          <h5 style={headingStyle}>2.2 Account Security</h5>
          <p>
            You are responsible for maintaining the confidentiality of your
            account credentials and ensuring the security of your account. You
            agree to notify Salon Wallah immediately if you suspect any
            unauthorized access to your account.
          </p>
          <h5 style={headingStyle}>2.3 Verification</h5>
          <p>
            Salon Wallah may require additional verification of your identity
            and credentials as a service provider. This may include submitting
            relevant licenses, certifications, and insurance documentation.
          </p>

          <br />

          <h4 style={headingStyle}>3. Services Offered</h4>
          <br />
          <h5 style={headingStyle}>3.1 Quality Assurance</h5>
          <p>
            As a partner with Salon Wallah, you are responsible for delivering
            high-quality services to clients. You agree to adhere to
            professional standards, provide accurate service descriptions, and
            ensure a safe and clean environment for clients.
          </p>
          <h5 style={headingStyle}>3.2 Service Availability</h5>
          <p>
            You have the flexibility to manage your service availability and
            schedule through our platform. Clients will book appointments based
            on your availability, and you are responsible for honoring those
            appointments.
          </p>
          <h5 style={headingStyle}>3.3 Pricing and Payments</h5>
          <p>
            You have the freedom to set your service prices and any additional
            charges. Salon Wallah will facilitate client payments and will
            deduct applicable service fees before transferring payments to you.
            It is your responsibility to keep your pricing information up to
            date.
          </p>

          <br />

          <h4 style={headingStyle}>4. Client Interactions</h4>
          <br />
          <h5 style={headingStyle}>4.1 Communication</h5>
          <p>
            You agree to communicate with clients in a professional and timely
            manner. This includes responding to client inquiries, confirming
            appointments, and addressing any client concerns or issues promptly.
          </p>
          <h5 style={headingStyle}>4.2 Cancellations and Refunds</h5>
          <p>
            Cancellation and refund policies are determined by you, the service
            provider. It is your responsibility to clearly communicate your
            cancellation and refund policies to clients during the booking
            process.
          </p>
          <h5 style={headingStyle}>4.3 Client Feedback</h5>
          <p>
            Clients may leave feedback and reviews about their experiences with
            you. While Salon Wallah encourages honest and constructive feedback,
            we reserve the right to remove or edit content that violates our
            guidelines.
          </p>

          <br />

          <h4 style={headingStyle}>5. Intellectual Property</h4>
          <br />
          <h5 style={headingStyle}>5.1 Ownership</h5>
          <p>
            Salon Wallah and its licensors retain all rights, title, and
            interest in and to our platform, including all content, graphics,
            logos, and software. You are granted a limited, non-exclusive, and
            non-transferable license to use our platform for the purpose of
            providing your services.
          </p>
          <h5 style={headingStyle}>5.2 Trademarks</h5>
          <p>
            All trademarks, service marks, logos, and trade names displayed on
            our platform are the property of Salon Wallah or their respective
            owners. You may not use these marks without our prior written
            consent.
          </p>

          <br />

          <h4 style={headingStyle}>6. Data and Privacy</h4>
          <br />
          <h5 style={headingStyle}>6.1 Data Collection</h5>
          <p>
            Salon Wallah collects and processes data from clients and partners
            in accordance with our Privacy Policy. By using our platform, you
            agree to abide by our Privacy Policy.
          </p>

          <br />

          <h4 style={headingStyle}>7. Termination</h4>
          <br />
          <p>
            Salon Wallah reserves the right to suspend or terminate your partner
            account at our discretion, without prior notice, if we believe you
            have violated these Terms or engaged in any fraudulent or illegal
            activities.
          </p>

          <br />

          <h4 style={headingStyle}>8. Disclaimers</h4>
          <br />
          <h5 style={headingStyle}>8.1 No Warranty</h5>
          <p>
            Our platform is provided "as is" and "as available." We do not make
            any warranties, express or implied, regarding the quality,
            reliability, or availability of our platform or services.
          </p>
          <h5 style={headingStyle}>8.2 Limitation of Liability</h5>
          <p>
            To the extent permitted by applicable laws, Salon Wallah and its
            affiliates, officers, directors, employees, and agents shall not be
            liable for any indirect, incidental, special, consequential, or
            punitive damages, or any loss of profits or revenues.
          </p>

          <br />

          <h4 style={headingStyle}>9. Governing Law and Dispute Resolution</h4>
          <br />
          <p>
            These Terms are governed by the laws of Govt. of India. Any disputes
            or claims arising out of or in connection with these Terms shall be
            subject to the exclusive jurisdiction of the courts in Govt. of
            India.
          </p>

          <br />

          <h4 style={headingStyle}>10. Contact Us</h4>
          <br />
          <p>
            If you have any questions, concerns, or feedback regarding these
            Terms or your partnership with Salon Wallah, please contact us at
            [Insert Contact Information].
          </p>

          <br />
        </article>
      </section>
    </div>
  );
}

export default TermsPage;
