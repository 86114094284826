import axios from "axios";

const salon_live = process.env.REACT_APP_SALON_BACKEND_LIVE_URL;

export async function admin_get_tokens() {
  let data = {};

  await axios
    .post(`${salon_live}/admin/login`, {
      email: process.env.REACT_APP_ADMIN_USERMAIL,
      password: process.env.REACT_APP_ADMIN_PASSWORD,
    })
    .then((res) => {
      data = res.data;
    })
    .catch((err) => console.log(err));

  return data;
}

export async function admin_get_blogs_list(token) {
  let data = {};

  await axios
    .get(`${salon_live}/admin/blogs/list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      data = res.data.data;
    })
    .catch((err) => console.log(err));

  return data;
}

export async function admin_get_gallery_list(token) {
  let data = {};

  await axios
    .get(`${salon_live}/admin/gallery/list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      data = res.data.data;
    })
    .catch((err) => console.log(err));

  return data;
}

export async function admin_get_services_list(token) {
  let data = {};

  await axios
    .get(`${salon_live}/admin/services/list/admin`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      data = res.data.data;
    })
    .catch((err) => console.log(err));

  return data;
}

export async function admin_get_testimonials_list(token) {
  let data = {};

  await axios
    .get(`${salon_live}/admin/testimonials/list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      data = res.data.data;
    })
    .catch((err) => console.log(err));

  return data;
}

export async function admin_get_coupon_list() {
  await axios
    .get(`${salon_live}/coupons/list`)
    .then((res) => {
      console.log("coupons list : ", res.data.rows);
      // store in a state
    })
    .catch((err) => console.log(err));
}
