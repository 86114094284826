import axios from "axios";

const salon_live = process.env.REACT_APP_SALON_BACKEND_LIVE_URL;

export async function post_query_form(type, subject, name, email, query) {
  await axios
    .post(
      `${salon_live}/query/add`,
      {
        type: type,
        subject: subject,
        name: name,
        email: email,
        query: query,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    );
}
