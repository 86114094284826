import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import constant from "../assets/constants.json";
import defaultImg from "../assets/blog2.jpg";

function LocationsCarousel({ locations }) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
      partialVisibilityGutter: 10,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1,
      partialVisibilityGutter: 10,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 10,
    },
  };

  return (
    <>
      <Carousel
        draggable={true}
        centerMode={false}
        swipeable={true}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={1000}
        transitionDuration={1000}
        ssr={true}
      >
        {locations.map((location, id) => (
          <div className="m-2 h-100" key={id}>
            <div
              className="container"
              style={{
                backgroundColor: "white",
                padding: "0.75vmax",
                height: "100%",
              }}
            >
              <div
                className="container p-0"
                style={{
                  overflow: "hidden",
                  aspectRatio: "4/5",
                }}
              >
                <img
                  src={location.image ? location.image : defaultImg}
                  style={{
                    objectFit: "cover",
                    height: "100%",
                    objectPosition: "center",
                  }}
                />
              </div>
              <p
                className="mt-3"
                style={{
                  color: constant.color.primary,
                  fontWeight: "600",
                  textTransform: "capitalize",
                  fontFamily: constant.font.heading.family,
                  fontSize: "calc( 14px + .5vw )",
                }}
              >
                {location.name ? location.name : "Salon Name"}
              </p>
              <p
                className="mt-3"
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                  textTransform: "capitalize",
                  fontFamily: constant.font.sub.family,
                  margin: "0",
                  marginTop: "8px",
                  textAlign: "left",
                }}
              >
                {location.address + ", " + location.pincode}
              </p>
            </div>
          </div>
        ))}
      </Carousel>
    </>
  );
}

export default LocationsCarousel;
