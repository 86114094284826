import React from "react";
import { MDBCol, MDBContainer, MDBFooter, MDBRow } from "mdb-react-ui-kit";
import {
  ImFacebook,
  ImTwitter,
  ImGoogle,
  ImInstagram,
  ImLinkedin,
} from "react-icons/im";

import logo from "../assets/sw-brand-logo.png";
import constants from "../assets/constants.json";

import appStoreBtnImg from "../assets/download_app_store.png";
import playStoreBtnImg from "../assets/download_play_store.png";

import cnt from "../assets/constants.json";

function Footer() {
  let year = new Date().getFullYear();

  return (
    <MDBFooter
      bgColor="dark"
      className="text-center text-lg-start "
      style={{ boxShadow: "-1px 0 2px gray", color: "whitesmoke" }}
    >
      {/* <section className="d-flex justify-content-center justify-content-lg-between p-4">
        <div className="me-5 d-none d-lg-block">
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a href="https://www.facebook.com/" className="me-4 text-reset">
            <ImFacebook />
          </a>
          <a href="https://www.twitter.com/" className="me-4 text-reset">
            <ImTwitter />
          </a>
          <a href="https://www.google.com/" className="me-4 text-reset">
            <ImGoogle />
          </a>
          <a href="https://www.instagram.com/" className="me-4 text-reset">
            <ImInstagram />
          </a>
          <a href="https://www.linkedin.com/" className="me-4 text-reset">
            <ImLinkedin />
          </a>
        </div>
      </section> */}

      {/* <section className="container text-light pt-3">
        <h6 style={{ fontSize: "calc(0.5rem + 0.5vw)", color: "gray" }}>
          Join the Salon Wallah Family
        </h6>
        <p style={{ fontSize: "calc(0.35rem + 0.5vw)", color: "gray" }}>
          Partnering with Salon Wallah means joining a community that values
          excellence in the beauty and wellness industry. Elevate your salon's
          reach, connect with new clients, and take your business to new
          heights.
        </p>
      </section> */}

      <section
        className="row justify-content-center justify-content-center p-4 align-items-start"
        style={{ rowGap: "1rem" }}
      >
        <div className="col-xs-12 col-sm-12 col-md-3 align-self-center">
          <div
            className="mx-auto"
            style={{
              height: "3.5rem",
              aspectRatio: "1/1",
              borderRadius: "10px",
              overflow: "hidden",
              objectPosition: "center",
            }}
          >
            <img
              src={logo}
              style={{
                height: "100%",
                objectFit: "contain",
                transform: "scale(1.6) translateY(18%)",
              }}
            />
          </div>
          <div className="row align-items-center mt-1">
            <p
              className="col"
              style={{
                fontSize: "calc(1.2rem + .5vw)",
                fontWeight: "500",
                fontFamily: constants.font.heading.family,
                marginBottom: "0",
                color: constants.color.primary,
                marginLeft: "6px",
                lineHeight: "0.8rem",
                textAlign: "center",
                marginTop: "6px",
              }}
            >
              SalonWallah
              <br />
              <span
                style={{
                  fontSize: "calc(0.5rem + .1vw)",
                  fontWeight: "500",
                  marginBottom: "0",
                  color: constants.color.primary,
                  textTransform: "uppercase",
                  textAlign: "center",
                }}
              >
                Salon, Spa, Parlour
              </span>
            </p>
          </div>
          <div className="text-center px-3 pt-3">
            <p style={{ fontSize: "calc(0.5rem + 0.4vw)" }}>
              Village Gijhore, Sector-53, Noida District-G.B.Nagar, U.P.
            </p>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-3 text-center text-lg-start">
          <div>
            <a
              href="/about"
              className=" text-reset"
              style={{ whiteSpace: "nowrap" }}
            >
              About Us
            </a>
          </div>
          <div>
            <a
              href="/contact"
              className="text-reset"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              Contact Us
            </a>
          </div>
          <div>
            <a
              href="/blog"
              className="text-reset"
              style={{ whiteSpace: "nowrap" }}
            >
              Blog
            </a>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-3">
          <p>Connect with us</p>
          <div className="text-center text-lg-start">
            <a
              href="https://www.facebook.com/people/Salon-Wallah/61551934917322/?is_tour_completed=true"
              className="text-reset d-flex justify-content-center justify-content-lg-start align-items-center "
            >
              <ImFacebook style={{ marginRight: "8px" }} /> Facebook
            </a>
          </div>
          <div className="text-center text-lg-start">
            <a
              href="https://www.instagram.com/salonwallah1/"
              className="text-reset d-flex justify-content-center justify-content-lg-start align-items-center "
            >
              <ImInstagram style={{ marginRight: "8px" }} />
              Instagram
            </a>
          </div>
          <div className="text-center text-lg-start">
            <a
              href="https://www.linkedin.com/in/salon-wallah-722638293/"
              className="text-reset d-flex justify-content-center justify-content-lg-start align-items-center "
            >
              <ImLinkedin style={{ marginRight: "8px" }} /> LinkedIn
            </a>
          </div>
          <div className="text-center text-lg-start">
            <a
              href="https://twitter.com/SalonWallah1"
              className="text-reset d-flex justify-content-center justify-content-lg-start align-items-center "
            >
              <ImTwitter style={{ marginRight: "8px" }} /> X
            </a>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-3">
          <div>
            <a
              href="/privacy"
              className="text-reset"
              style={{ whiteSpace: "nowrap" }}
            >
              Privacy Policy
            </a>
          </div>

          <div>
            <a
              href="/tnc"
              className="text-reset"
              style={{ whiteSpace: "nowrap" }}
            >
              Terms and Conditions
            </a>
          </div>

          <div>
            <a
              href="/faq"
              className="text-reset"
              style={{ whiteSpace: "nowrap" }}
            >
              FAQ
            </a>
          </div>

          <div>
            <a
              href="/benefits"
              className="text-reset"
              style={{ whiteSpace: "nowrap" }}
            >
              Benefits
            </a>
          </div>

          <div>
            <a
              href="/sitemap"
              className="text-reset"
              style={{ whiteSpace: "nowrap" }}
            >
              Sitemap
            </a>
          </div>
        </div>
      </section>

      <section className="d-flex justify-content-center justify-content-lg-between py-1 px-4  align-items-center">
        <div className="me-5 d-none d-lg-block">
          <span>Get the app:</span>
        </div>
        <div>
          <a href="" className="me-4 text-reset">
            <img
              // style={{ boxShadow: "0 0 2px 2px white" }}
              src={appStoreBtnImg}
              height={"35vmax"}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.salonwallah"
            target="_blank"
            className="me-4 text-reset"
          >
            <img
              // style={{ boxShadow: "0 0 2px 2px white" }}
              src={playStoreBtnImg}
              height={"35vmax"}
            />
          </a>
        </div>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: cnt.color.primary, color: "white" }}
      >
        Salon Wallah © {year} Copyright
      </div>
    </MDBFooter>
  );
}

export default Footer;
