import React, { useEffect, useState } from "react";

import f1img from "../assets/f-1.jpeg";
import f2img from "../assets/f-2.jpeg";
import f3img from "../assets/f-3.jpeg";
import f4img from "../assets/f-4.jpeg";
import f5img from "../assets/f-5.jpeg";
import f8img from "../assets/f-8.jpeg";
import s1img from "../assets/s-1.jpg";
import s2img from "../assets/s-2.jpg";
import s3img from "../assets/s-3.jpg";
import s4img from "../assets/s-4.jpg";
import srv1Img from "../assets/srv-1.jpg";
import srv2Img from "../assets/srv-2.jpg";
import g5Img from "../assets/gallery-5.jpg";
import blogBgBannerImg from "../assets/blog-bg1.jpg";
import styleImg6 from "../assets/style-6.jpg";
import styleImg7 from "../assets/style-7.jpg";

import home1Img from "../assets/home-1.jpg";
import home2Img from "../assets/home-2.jpg";
import home3Img from "../assets/home-3.jpg";

import brandLogo from "../assets/sw-brand-logo.png";

import homepageBannerVideo from "../assets/videos/woman-styling-horizontal.mp4";

import appStoreBtnImg from "../assets/download_app_store.png";
import playStoreBtnImg from "../assets/download_play_store.png";

import GalleryCarousel from "../components/GalleryCarousel";
import ServiceFeatures from "../components/ServiceFeatures";
import Testimonials from "../components/Testimonials";

import constant from "../assets/constants.json";
import "../style/HomePage.css";
import { NavLink } from "react-bootstrap";
import ServicesCarousel from "../components/ServicesCarousel";
import LocationsCarousel from "../components/LocationsCarousel";
import {
  vendor_salon_locations_list,
  vendor_services_list,
} from "../services/vendor";

const style_head_hr = {
  width: "120px",
  border: "none",
  backgroundColor: constant.color.primary,
  height: "2px",
  opacity: "1",
  margin: "32px 0 0 0 ",
};

const sub_head_txt_above = {
  color: constant.color.primary,
  fontWeight: "400",
  fontFamily: constant.font.heading.family,
};

const sub_head_txt = {
  fontWeight: "500",
  fontFamily: constant.font.sub.family,
  margin: "0",
  marginTop: "8px",
};

const sub_head_timing_day = {
  fontFamily: constant.font.heading.family,
  fontWeight: "900",
};

const section_head = {
  fontWeight: "700",
  fontFamily: constant.font.sub.family,
  margin: "0",
  marginTop: "8px",
};

const servicesList1 = [
  {
    title: "HAIRCUTS",
    img: s1img,
  },
  {
    title: "BEARD & MORE",
    img: s2img,
  },
  {
    title: "SHAVING",
    img: s3img,
  },
  {
    title: "STYLING",
    img: s4img,
  },
];

function Homepage() {
  const [locationsList, setLocations] = useState([]);

  const getHomeData = async () => {
    // get data for the services and locations
    let loc_data = await vendor_salon_locations_list();

    // from locations only get name, image and address, with pincode
    setLocations(
      loc_data.map((loc, id) => {
        return {
          image: loc.Images[0],
          address: loc.address,
          name: loc.name,
          pincode: loc.pincode,
          _id: id,
        };
      })
    );
  };

  useEffect(() => {
    async function getSomeData() {
      await getHomeData();
    }

    getSomeData();
    return () => {};
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <section id="intro_1" className="p-0">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div
              className="bg-image"
              style={{
                height: "100vh",
                overflow: "hidden",
                // backgroundImage: `url(${bannerImg})`,
                backgroundAttachment: "fixed",
                backgroundColor: "transparent",
                backgroundPosition: "0 0",
              }}
            >
              <video muted playsInline autoPlay loop id="homepageBannerVideo">
                <source src={homepageBannerVideo} type="video/mp4" />
              </video>
              {/* <img
                src={bannerImg}
                alt="Sample"
                style={{
                  objectFit: "cover",
                  objectPosition: "left bottom",
                }}
              /> */}
              <div
                className="mask"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
              >
                <div className="d-flex flex-row justify-content-end text-light align-items-center h-100">
                  <div className="container-fluid d-flex flex-column justify-content-center text-end w-80 mx-3">
                    <p
                      style={{
                        fontFamily: constant.font.heading.family,
                        fontWeight: "400",
                        textTransform: "uppercase",
                        fontSize: "calc( 0.65rem + 0.5vw )",
                        lineHeight: "1.2rem",
                        marginBottom: "3px",
                        color: "white",
                      }}
                    >
                      Book the Finest Salon, Parlor, and Spa Services Near You
                    </p>
                    <p
                      style={{
                        fontFamily: constant.font.sub.family,
                        fontSize: "9vmin",
                      }}
                    >
                      Welcome to SALON WALLAH
                    </p>
                    <br />
                    <div className="row justify-content-end">
                      <a
                        href=""
                        className="col-5 col-sm-4 col-md-3 col-lg-2 col-xl-2 text-reset"
                      >
                        <img
                          src={appStoreBtnImg}
                          loading="lazy"
                          height={"50vmin"}
                        />
                      </a>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.salonwallah"
                        target="_blank"
                        className="col-5 col-sm-4 col-md-3 col-lg-2 col-xl-2 text-reset"
                      >
                        <img
                          src={playStoreBtnImg}
                          loading="lazy"
                          height={"50vmin"}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="intro_2" style={{ backgroundColor: constant.color.light }}>
        <div className="container py-5 ">
          <div className="row g-2 justify-content-center align-items-center">
            {/* <div className="col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 text-start my-5"> */}
            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-start my-5">
              <div className="container">
                <h5 style={sub_head_txt_above}>
                  Tired of getting in queue for your Beauty Treatment?
                </h5>
                <h2 style={sub_head_txt}>WE ARE SALON WALLAH</h2>
                <hr style={style_head_hr} />
                <p style={{ marginTop: "32px" }}>
                  Gone are the days when you have to wait for your turn for
                  grooming. We understand that life gets busy, and self-care
                  often takes a backseat. That's where we come in. Choose from
                  the best and schedule an appointment at your favorable time.
                  We've partnered with a curetted selection of top-notch salons,
                  parlors, and spas in your vicinity, ensuring that you can
                  access premium beauty services without the hassle.
                </p>
                <br />
                <p>
                  For any business or service related queries, feel free to
                  contact us
                </p>
                <div className="row g-3 justify-content-start">
                  <NavLink
                    className="col-12 primary_btn_style mr-1"
                    style={{
                      width: "max-content",
                      fontSize: "0.85rem",
                      backgroundColor: constant.color.primary,
                      marginRight: "1rem",
                    }}
                    href="/contact/#contact-form"
                  >
                    Partner with Us
                  </NavLink>
                  <NavLink
                    className="col-12 primary_btn_style ml-1"
                    style={{
                      width: "max-content",
                      fontSize: "0.85rem",
                      backgroundColor: constant.color.primary,
                    }}
                    href="/contact/#contact-form"
                  >
                    Get an Appointment
                  </NavLink>
                </div>
                <p style={{ margin: "32px 0" }}>
                  Thanks for supporting Salon Wallah!
                </p>
              </div>
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
              <div className="container">
                <img src={home1Img} loading="lazy" style={{ width: "100%" }} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services" style={{ backgroundColor: "white" }}>
        <div className="container py-5">
          <h5 style={sub_head_txt_above}>
            EXPERIENCE BEAUTY SERVICES IN THE COMFORT OF YOUR HOME
          </h5>
          <h1 style={section_head}>OUR SERVICES</h1>
          <div className="container text-start my-5">
            <p>
              Indulge in the ultimate luxury - professional beauty services,
              right at your doorstep. At-home beauty services are the new
              standard of convenience and self-care. Discover a world where
              pampering and relaxation come to you.
            </p>

            <p style={{ fontWeight: "bold", marginTop: "3rem" }}>
              Our Range of At-Home Services
            </p>

            <p>
              From rejuvenating facials and soothing massages to expert
              hairstyling and precise grooming, our team of professionals is
              equipped to provide a wide range of beauty services in the comfort
              of your home.
            </p>
          </div>

          <div className="row justify-content-center">
            <NavLink
              className="col-12 primary_btn_style ml-1"
              style={{
                width: "max-content",
                fontSize: "0.85rem",
                backgroundColor: constant.color.primary,
              }}
              href="/contact/#contact-form"
            >
              Get an Appointment
            </NavLink>
          </div>

          <ServicesCarousel />

          <div className="row text-start">
            <div className="col-12 col-md-6 col-lg-4 col-xl-4">
              <img src={styleImg7} width={"100%"} loading="lazy" />
            </div>
            <div className="col-12 col-md-6 col-lg-8 col-xl-8">
              <p style={{ fontWeight: "bold" }}>
                Why Choose At-Home Beauty Services?
              </p>
              <ul>
                <li className="custom-scissor-bullets">
                  <span style={{ color: constant.color.primary }}>
                    Time-Saving:
                  </span>{" "}
                  Say goodbye to the commute and waiting times at salons. With
                  at-home beauty services, you have more time for yourself and
                  your busy schedule.
                </li>
                <li className="custom-scissor-bullets">
                  <span style={{ color: constant.color.primary }}>
                    Comfort:
                  </span>{" "}
                  Your home is your sanctuary. Enjoy treatments and services in
                  a space where you feel most relaxed.
                </li>
                <li className="custom-scissor-bullets">
                  <span style={{ color: constant.color.primary }}>
                    Personalized Experience:
                  </span>{" "}
                  Your needs, your preferences. At-home services are tailored to
                  you, ensuring a personalized beauty experience.
                </li>
                <li className="custom-scissor-bullets">
                  <span style={{ color: constant.color.primary }}>
                    No Stress:
                  </span>{" "}
                  Skip the hassle of traffic and parking. We bring the salon to
                  you, saving you both time and stress.
                </li>
              </ul>

              <p style={{ fontWeight: "bold", marginTop: "1.5rem" }}>
                How It Works?
              </p>
              <ul>
                <li className="custom-scissor-bullets">
                  <span style={{ color: constant.color.primary }}>
                    Book Your Appointment:
                  </span>{" "}
                  Choose your desired service, date, and time through our
                  user-friendly platform.
                </li>
                <li className="custom-scissor-bullets">
                  <span style={{ color: constant.color.primary }}>
                    Professional Team:
                  </span>{" "}
                  Our skilled beauty experts will arrive at your doorstep, fully
                  equipped and ready to pamper you.
                </li>
                <li className="custom-scissor-bullets">
                  <span style={{ color: constant.color.primary }}>
                    Relax and Enjoy:
                  </span>{" "}
                  Sit back, unwind, and let the experts take care of the rest.
                  Your well-being is our top priority.
                </li>
              </ul>
            </div>
          </div>

          <div className="container text-start my-3">
            <p>
              Rediscover the joy of self-care without leaving your home. It's
              time to prioritize yourself, reclaim your time, and enjoy beauty
              services that revitalize and refresh. Book your at-home beauty
              experience today and elevate your self-care routine.
            </p>
          </div>

          <div className="row justify-content-center">
            <NavLink
              className="col-12 primary_btn_style ml-1"
              style={{
                width: "max-content",
                fontSize: "0.85rem",
                backgroundColor: constant.color.primary,
              }}
              href="/contact/#contact-form"
            >
              Get an Appointment
            </NavLink>
          </div>
        </div>
      </section>

      <section id="service_gallery" style={{ backgroundColor: "white" }}>
        <div className="container py-5">
          <h5 style={sub_head_txt_above}>EXPERTISE</h5>
          <h1 style={section_head}>SALON FULL SERVICE</h1>
          <div className="container my-5">
            <div className="grid-homepage-gallery">
              <div className="f1img">
                <img
                  src={f1img}
                  loading="lazy"
                  style={{ width: "100%", objectFit: "scale-down" }}
                />
              </div>
              <div className="f2img">
                <img
                  src={srv1Img}
                  loading="lazy"
                  style={{ width: "100%", objectFit: "scale-down" }}
                />
              </div>
              <div className="f3img">
                <img
                  src={f3img}
                  loading="lazy"
                  style={{ width: "100%", objectFit: "scale-down" }}
                />
              </div>
              <div className="f4img">
                <img
                  src={srv2Img}
                  loading="lazy"
                  style={{ width: "100%", objectFit: "scale-down" }}
                />
              </div>
              <div className="f5img">
                <img
                  src={f5img}
                  loading="lazy"
                  style={{ width: "100%", objectFit: "scale-down" }}
                />
              </div>
              <div className="f6img">
                <img
                  src={g5Img}
                  loading="lazy"
                  style={{ width: "100%", objectFit: "scale-down" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="partner" style={{ backgroundColor: constant.color.light }}>
        <div className="container py-5">
          <h5 style={sub_head_txt_above}>PARTNER WITH SALON WALLAH</h5>
          <h1 style={section_head}>ELEVATE YOUR SALON EXPERIENCE</h1>
          <div className="container text-start my-5">
            <p>
              Are you a salon owner looking to expand your reach and offer your
              services to a broader audience? Salon Wallah invites you to join
              our network of top-tier salons, unlocking new opportunities for
              growth and success.
            </p>
            <div className="row justify-content-center">
              <NavLink
                className="col-12 primary_btn_style mr-1"
                style={{
                  width: "max-content",
                  fontSize: "0.85rem",
                  backgroundColor: constant.color.primary,
                  marginRight: "1rem",
                }}
                href="/contact/#contact-form"
              >
                Partner with Us
              </NavLink>
            </div>

            <p style={{ fontWeight: "bold", marginTop: "3rem" }}>
              Why Partner with Salon Wallah?
            </p>

            <div className="row">
              <div className="col-12 col-md-6 col-lg-8 col-xl-8">
                <ul>
                  <li className="custom-scissor-bullets">
                    <span style={{ color: constant.color.primary }}>
                      Increased Visibility:
                    </span>{" "}
                    By joining Salon Wallah, your salon gains exposure to a
                    larger clientele. We connect you with individuals seeking
                    beauty and wellness services in your area, increasing your
                    visibility and attracting potential customers.
                  </li>
                  <li className="custom-scissor-bullets">
                    <span style={{ color: constant.color.primary }}>
                      Convenience:
                    </span>{" "}
                    Our platform simplifies the booking process for both you and
                    your clients. With our user-friendly interface, clients can
                    easily schedule appointments with your salon, enhancing
                    their overall experience.
                  </li>
                  <li className="custom-scissor-bullets">
                    <span style={{ color: constant.color.primary }}>
                      Business Growth:
                    </span>{" "}
                    Partnering with us means access to a steady stream of
                    clients, helping you fill up your appointment slots and
                    maximize your salon's earning potential.
                  </li>
                  <li className="custom-scissor-bullets">
                    <span style={{ color: constant.color.primary }}>
                      Marketing Support:
                    </span>{" "}
                    We leverage our digital presence to promote your salon.
                    Benefit from our marketing efforts, including online
                    advertising and social media exposure.
                  </li>
                  <li className="custom-scissor-bullets">
                    <span style={{ color: constant.color.primary }}>
                      Client Management:
                    </span>{" "}
                    Salon Wallah offers tools to manage appointments and client
                    data efficiently, streamlining your operations and helping
                    you focus on delivering exceptional services.
                  </li>
                  <li className="custom-scissor-bullets">
                    <span style={{ color: constant.color.primary }}>
                      Community:
                    </span>{" "}
                    Become part of our network of like-minded salon owners who
                    share the same commitment to quality and customer
                    satisfaction.
                  </li>
                </ul>
              </div>

              <div className="col-12 col-md-6 col-lg-4 col-xl-4">
                <img src={styleImg6} width={"100%"} />
              </div>
            </div>

            <p style={{ fontWeight: "bold", marginTop: "2rem" }}>
              Joining is Simple
            </p>

            <div className="container">
              <ul>
                <li className="custom-scissor-bullets">
                  <span style={{ color: constant.color.primary }}>
                    Contact Us:
                  </span>{" "}
                  Reach out to us to express your interest in becoming a Salon
                  Wallah partner.
                </li>
                <li className="custom-scissor-bullets">
                  <span style={{ color: constant.color.primary }}>
                    Onboarding:
                  </span>{" "}
                  Our team will guide you through the onboarding process,
                  ensuring a smooth transition to our platform
                </li>
                <li className="custom-scissor-bullets">
                  <span style={{ color: constant.color.primary }}>
                    Start Connecting:
                  </span>{" "}
                  Once onboard, your salon will be listed on Salon Wallah, and
                  clients can begin booking appointments with you immediately.
                </li>
              </ul>
            </div>

            <div className="row justify-content-center">
              <img
                src={brandLogo}
                style={{ width: "calc( 12rem + .5vw)", margin: "2rem 0" }}
              />
            </div>
            <p
              style={{
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <p style={{ fontWeight: "bold", marginTop: "2rem" }}>
                Join the Salon Wallah Family
              </p>
            </p>

            <p style={{ textAlign: "center" }}>
              Partnering with Salon Wallah means joining a community that values
              excellence in the beauty and wellness industry. Elevate your
              salon's reach, connect with new clients, and take your business to
              new heights.
            </p>

            <div className="row justify-content-center">
              <NavLink
                className="col-12 primary_btn_style mr-1"
                style={{
                  width: "max-content",
                  fontSize: "0.85rem",
                  backgroundColor: constant.color.primary,
                  marginRight: "1rem",
                }}
                href="/contact/#contact-form"
              >
                Partner with Us
              </NavLink>
            </div>
          </div>
        </div>
      </section>

      <section id="mid_1" style={{ backgroundColor: "white" }}>
        <div className="container py-5">
          <div className="row">
            <div className="col-10 col-md-8 col-lg-8 col-xl-8 container">
              <img
                src={home2Img}
                loading="lazy"
                style={{ width: "100%", height: "80%", objectFit: "cover" }}
              />
            </div>
            <div
              className="col-11 col-md-6 col-lg-6 col-xl-6 container text-start"
              style={{
                backgroundColor: "#000",
                opacity: "0.85",
                padding: "16px",
                height: "fit-content",
                translate: "0 -12vmax",
              }}
            >
              <div
                style={{
                  border: "1px solid white",
                  backgroundColor: "#000",
                  padding: "48px 16px",
                  opacity: "0.85",
                }}
              >
                <h5 style={sub_head_txt_above}>WE SPECIALIZE</h5>
                <h2 style={{ ...sub_head_txt, color: "white" }}>HOT SHAVES</h2>
                <p
                  style={{
                    marginTop: "32px",
                    color: "white",
                    fontSize: "1.1rem",
                    lineHeight: "2rem",
                  }}
                >
                  We specialize in Hot Shaves and the care of Facial Hair
                  (beards), The Straight Razor Shave is the facial for men. The
                  experience is definitely a treat. There’s nothing like a hot
                  towel on your face or the fragrance of Hot shaving cream to
                  give you that fresh invigorated feeling.
                </p>
              </div>
            </div>
            <div className="col-8 col-md-4 col-lg-4 col-xl-4 container">
              <div
                className="container"
                style={{
                  border: "1px solid black",
                  backgroundColor: "transparent",
                  padding: "16px",
                }}
              >
                <div className="container p-0">
                  <img
                    src={f8img}
                    loading="lazy"
                    style={{ width: "100%", objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="info_1" style={{ backgroundColor: constant.color.light }}>
        <div className="container py-5">
          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              <div
                className="container"
                style={{ aspectRatio: "1/1", overflow: "hidden" }}
              >
                <img
                  src={home3Img}
                  loading="lazy"
                  style={{
                    height: "100%",
                    width: "inherit",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-6 text-start">
              <div className="container py-2">
                <h5 style={sub_head_txt_above}>
                  LOOKING FOR A BEAUTIFUL STYLE
                </h5>
                <h2 style={sub_head_txt}>
                  WHAT ARE THE SECRETS OF THE DESIGN?
                </h2>
                <hr style={style_head_hr} />
                <p style={{ marginTop: "32px" }}>
                  We are a Unisex Salon Brand. We pride ourselves on Masterful
                  Designs and Beauty Services. Our Professionals are
                  accomplished in their craft, specializing in unisex salon
                  treatments. There are many variations of beauty designs and
                  services available, but the majority are available in our
                  stores by our professionals.
                </p>
                {/* <button className="primary_btn_style">FIND OUT</button> */}
                <NavLink
                  className="primary_btn_style"
                  style={{
                    width: "max-content",
                    fontSize: "0.85rem",
                    backgroundColor: constant.color.primary,
                  }}
                  href="/about"
                >
                  FIND OUT
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        style={{
          backgroundImage: `url(${blogBgBannerImg})`,
          backgroundSize: "cover",
          height: "50vmin",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
        }}
      >
        <div
          style={{ height: "inherit", backdropFilter: "brightness(50%)" }}
          className="container-fluid"
        >
          <div className="row align-items-center h-100">
            <div className="col ">
              <h1
                style={{
                  ...sub_head_txt_above,
                  color: constant.color.light,
                  fontWeight: "600",
                  letterSpacing: "6px",
                }}
              >
                WE MAKE IT HAPPEN
              </h1>
            </div>
          </div>
        </div>
      </section>

      <section
        id="locations"
        style={{ backgroundColor: constant.color.heavy_light }}
      >
        <div className="container py-5">
          <h5 style={sub_head_txt_above}>WE CAN MAKE YOUR AWESOMENESS</h5>
          <h1 style={{ ...section_head, marginBottom: "1rem" }}>
            OUR LOCATIONS
          </h1>

          <LocationsCarousel locations={locationsList} />
        </div>
      </section>

      <section id="testimonials">
        <Testimonials />
      </section>
    </div>
  );
}

export default Homepage;
