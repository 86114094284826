import React from "react";

import img from "../assets/sample-2.jpg";

function ServiceFeatures() {
  return (
    <>
      <section className=" row justify-content-center container-fluid py-5 mx-0">
        <div className="row gy-3 justify-content-center">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <picture>
              <div className="container">
                <img src={img} alt="Pic 1" width={"100%"} />
              </div>
            </picture>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-start py-auto">
            <div className="container">
              <label
                style={{
                  fontSize: "1.4rem",
                  lineHeight: "1.7rem",
                  textTransform: "uppercase",
                  letterSpacing: ".24rem",
                  fontFamily: "proxima-nova,sans-serif",
                  fontWeight: "100",
                  marginBottom: "1.2rem",
                }}
              >
                New Listings
              </label>
              <br />
              <h2
                style={{
                  margin: "0 0 1rem",
                  fontSize: "3.5rem",
                  fontWeight: "900",
                  lineHeight: "3.8rem",
                  fontFamily: "georgia, 'source serif pro', serif",
                  WebkitFontSmoothing: "antialiased",
                  letterSpacing: "0rem",
                }}
              >
                Never Run out of space
              </h2>
              <p
                style={{
                  margin: "0 0 1.1rem",
                  display: "block",
                  marginBlockStart: "1em",
                  marginBlockEnd: "1em",
                  marginInlineStart: "0px",
                  marginInlineEnd: "0px",
                  fontSize: "1.2rem",
                  lineHeight: "1.7rem",
                }}
              >
                Sell any size inventory with the flexibility of Online Store
                from Bluehost. Harness the power of WooCommerce for unlimited
                listings, and provide visitors with unique descriptions they can
                use to find products quickly.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className=" row justify-content-center container-fluid py-5 mx-0">
        <div className="row gy-3 justify-content-center">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-start py-auto">
            <div className="container">
              <label
                style={{
                  fontSize: "1.4rem",
                  lineHeight: "1.7rem",
                  textTransform: "uppercase",
                  letterSpacing: ".24rem",
                  fontFamily: "proxima-nova,sans-serif",
                  fontWeight: "100",
                  marginBottom: "1.2rem",
                }}
              >
                STORE PERFORMANCE
              </label>
              <br />
              <h2
                style={{
                  margin: "0 0 1rem",
                  fontSize: "3.5rem",
                  fontWeight: "900",
                  lineHeight: "3.8rem",
                  fontFamily: "georgia, 'source serif pro', serif",
                  WebkitFontSmoothing: "antialiased",
                  letterSpacing: "0rem",
                }}
              >
                View sales at a glance.
              </h2>
              <p
                style={{
                  margin: "0 0 1.1rem",
                  display: "block",
                  marginBlockStart: "1em",
                  marginBlockEnd: "1em",
                  marginInlineStart: "0px",
                  marginInlineEnd: "0px",
                  fontSize: "1.2rem",
                  lineHeight: "1.7rem",
                }}
              >
                Quickly view your total sales on a user-friendly dashboard,
                which helps you stay current on store performance. You can
                analyze sales in the moment, or view changing trends over time.
                Then, make your updates with ease.
              </p>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <picture>
              <div className="container">
                <img src={img} alt="Pic 1" width={"100%"} />
              </div>
            </picture>
          </div>
        </div>
      </section>

      <section className=" row justify-content-center container-fluid py-5 mx-0">
        <div className="row gy-3 justify-content-center">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <picture>
              <div className="container">
                <img src={img} alt="Pic 1" width={"100%"} />
              </div>
            </picture>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-start py-auto">
            <div className="container">
              <label
                style={{
                  fontSize: "1.4rem",
                  lineHeight: "1.7rem",
                  textTransform: "uppercase",
                  letterSpacing: ".24rem",
                  fontFamily: "proxima-nova,sans-serif",
                  fontWeight: "100",
                  marginBottom: "1.2rem",
                }}
              >
                RECENT ORDER HISTORY
              </label>
              <br />
              <h2
                style={{
                  margin: "0 0 1rem",
                  fontSize: "3.5rem",
                  fontWeight: "900",
                  lineHeight: "3.8rem",
                  fontFamily: "georgia, 'source serif pro', serif",
                  WebkitFontSmoothing: "antialiased",
                  letterSpacing: "0rem",
                }}
              >
                Get the order cheatsheet.
              </h2>
              <p
                style={{
                  margin: "0 0 1.1rem",
                  display: "block",
                  marginBlockStart: "1em",
                  marginBlockEnd: "1em",
                  marginInlineStart: "0px",
                  marginInlineEnd: "0px",
                  fontSize: "1.2rem",
                  lineHeight: "1.7rem",
                }}
              >
                With an active list of purchases from the last 24 hours, you can
                track shipping schedules and inventory volumes fast. Simply
                access your list with the Online Store dashboard, then search
                via name, zip code, or email.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceFeatures;
