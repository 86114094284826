import axios from "axios";

const salon_live = process.env.REACT_APP_SALON_BACKEND_LIVE_URL;
const vendor_auth_phone = process.env.REACT_APP_VENDOR_AUTH_PHONE;

export async function vendor_auth() {
  let data;

  await axios
    .post(`${salon_live}/vendor/auth`, {
      phone: vendor_auth_phone,
    })
    .then(
      (res) => {
        data = res.data;
      },
      (err) => {
        console.log(err);
      }
    )
    .finally(() => console.log("Auth complete"));

  return data;
}

export async function vendor_validateOtp() {
  let vendor_auth_data = await vendor_auth();
  let vendor_otp = String(vendor_auth_data.data.otp);

  let resdata;

  await axios
    .post(`${salon_live}/vendor/validateOtp`, {
      phone: vendor_auth_phone,
      otp: vendor_otp,
    })
    .then(
      (res) => {
        resdata = res.data;
        // store both tokens and verfication status in cache
        localStorage.setItem("vendorAccessToken", resdata.data.accessToken);

        localStorage.setItem("vendorRefreshToken", resdata.data.refreshToken);
        localStorage.setItem(
          "vendorVerifiedStatus",
          resdata.data.status === "verified" ? true : false
        );

        // console.log("vendor otp was validted");
        // console.log(resdata.data.accessToken);
      },
      (err) => {
        console.log(err);
      }
    )
    .finally(() => console.log("Vendor Otp validated"));

  //console.log(resdata);
  return resdata;
}

export async function vendor_refresh_token() {
  // check if token already exists in cache or not
  //console.log("inside refresh token  ");

  let vendor_after_validation_data = await vendor_validateOtp();
  let updatedTokensData;

  // refresh access token using refresh token
  await axios
    .post(`${salon_live}/vendor/token`, null, {
      headers: {
        Authorization: `Bearer ${vendor_after_validation_data.data.refreshToken}`,
      },
    })
    .then(
      (res) => {
        console.log(res);
        localStorage.setItem("vendorAccessToken", res.data.data.accessToken);
        localStorage.setItem("vendorRefreshToken", res.data.data.refreshToken);
        localStorage.setItem(
          "vendorVerifiedStatus",
          res.data.data.status === "verified" ? true : false
        );
        updatedTokensData = res.data;
      },
      (err) => {
        console.log(err);
      }
    );

  return updatedTokensData;
}

export async function vendor_services_list() {
  let servicesList;

  let tokensData = await vendor_validateOtp();

  await axios
    .get(`${salon_live}/services/category/list/vendor?page=1&limit=20`, {
      headers: {
        Authorization: `Bearer ${tokensData.data.accessToken}`,
        "Content-Type": "application/json",
      },
    })
    .then(
      (res) => {
        // rows is the list of service objects
        servicesList = res.data.data.rows;
      },
      (err) => {
        console.log(err);
      }
    );

  return servicesList;
}

export async function vendor_salon_locations_list() {
  let locationsList;

  // check if token already exists in cache or not
  let tokensData = await vendor_validateOtp();

  await axios
    .get(`${salon_live}/shops/list/vendor_app`, {
      headers: {
        Authorization: `Bearer ${tokensData.data.accessToken}`,
        "Content-Type": "application/json",
      },
    })
    .then(
      (res) => {
        // rows is the list of location objects
        locationsList = res.data.data.rows;
      },
      (err) => {
        console.log(err);
      }
    );

  return locationsList;
}
