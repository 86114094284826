import React from "react";
import "../style/AboutPage.css";

import bannerImg from "../assets/about-banner.jpg";
import about2Img from "../assets/about-2.jpg";
import about3Img from "../assets/about-3.jpg";
import aboutA2Img from "../assets/about-a1.jpeg";

import constant from "../assets/constants.json";
import { FaTwitter } from "react-icons/fa";

import vidImg from "../assets/videos/woman-hair-styling-vertical.mp4";

const sub_head_txt_above = {
  color: constant.color.primary,
  fontWeight: "400",
  fontFamily: constant.font.heading.family,
};

const section_head = {
  fontWeight: "700",
  fontFamily: constant.font.sub.family,
  margin: "0",
  marginTop: "8px",
  color: "white",
};

const style_head_hr = {
  width: "120px",
  border: "none",
  backgroundColor: constant.color.primary,
  height: "2px",
  opacity: "1",
  margin: "32px 0 0 0 ",
};

const sub_head_txt = {
  fontWeight: "500",
  fontFamily: constant.font.sub.family,
  margin: "0",
  marginTop: "8px",
};
function AboutPage() {
  return (
    <div style={{ textAlign: "center", marginTop: "3.5rem", color: "black" }}>
      <section>
        <div
          style={{
            height: "100vh",
            background: `url(${bannerImg})`,
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              width: "inherit",
              height: "inherit",
              backdropFilter: "brightness(70%)",
              textAlign: "right",
            }}
            className="row align-content-center"
          >
            <div className="col p-5">
              <h5
                style={{
                  ...sub_head_txt_above,
                  fontSize: "calc( 0.65rem + 0.5vw )",
                  lineHeight: "1.2rem",
                  color: constant.color.light,
                  textTransform: "uppercase",
                }}
              >
                Your Personal Beauty Concierge
              </h5>
              <h1 style={{ ...section_head, fontSize: "9vmin" }}>ABOUT US</h1>
            </div>
          </div>
        </div>
      </section>

      <section id="intro" style={{ backgroundColor: constant.color.light }}>
        <div className="container py-5">
          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              <div
                className="container"
                style={{ aspectRatio: "1/1", overflow: "hidden" }}
              >
                <img
                  src={about2Img}
                  style={{
                    height: "100%",
                    width: "inherit",
                    objectFit: "cover",
                  }}
                  alt="Fashion girl"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-6 text-start">
              <div className="container py-2">
                <h5 style={sub_head_txt_above}>WE ARE A STYLE BRAND</h5>
                <h2 style={sub_head_txt}>OUR MISSION</h2>
                <hr style={style_head_hr} />
                <p style={{ marginTop: "2rem" }}>
                  At Salon Wallah, we believe that beauty is more than skin
                  deep; it's about self-care, confidence, and convenience. Our
                  mission is simple yet powerful: to connect you with the best
                  salon, parlor, and spa services near you. We want to make
                  self-care accessible and enjoyable, tailored to your
                  preferences and schedule. <br />
                  <br />
                  It's time to prioritize self-care, embrace confidence, and
                  enjoy beauty services that revitalize and refresh. Join us on
                  this beauty journey today. Your path to beauty, relaxation,
                  and self-care begins here with Salon Wallah.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="gallery" className="my-5">
        <div className="container p-4">
          <div className="row g-4">
            <div className="col-12 col-md-6 col-lg-4 col-xl-4">
              <div
                style={{
                  width: "100%",
                  aspectRatio: "1/2",
                  overflow: "hidden",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <video
                  muted
                  playsInline
                  autoPlay
                  loop
                  style={{ width: "100%" }}
                >
                  <source src={vidImg} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-4">
              <div
                style={{
                  width: "100%",
                  aspectRatio: "5/7",
                  overflow: "hidden",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <img
                  src={about3Img}
                  style={{ height: "100%" }}
                  alt="Men haircut"
                />
              </div>
              <div className="col text-start p-4">
                <FaTwitter size={"3rem"} color="#1DA1F2" />
                <p
                  style={{
                    paddingTop: "1rem",
                    fontSize: "0.9rem",
                    color: constant.color.dark,
                  }}
                >
                  "Capturing market trends and newness in color, quality fabrics
                  and shapes, and expressing them in the effortless, relaxed and
                  comfortable style."
                </p>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-4 col-xl-4">
              <div className="container py-5">
                <h3 style={{ ...sub_head_txt_above, textAlign: "left" }}>
                  WHO WE ARE
                </h3>
                <h5
                  style={{
                    ...sub_head_txt_above,
                    textAlign: "right",
                    color: constant.color.dark,
                  }}
                >
                  AND WHAT WE DO
                </h5>
                <hr style={style_head_hr} />
                <p style={{ marginTop: "2rem", textAlign: "right" }}>
                  The company's reflects a positive and caring attitude towards
                  life that celebrates real people and togetherness according to
                  the brand promise:{" "}
                  <i
                    style={{ fontWeight: "700", color: constant.color.primary }}
                  >
                    “To connect you with the best salon, parlor, and spa
                    services near you. We want to make self-care accessible and
                    enjoyable, tailored to your preferences and schedule.”.
                  </i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="why_partner"
        style={{ backgroundColor: constant.color.light }}
      >
        <div className="container py-5">
          <h5 style={sub_head_txt_above}>FOR SALON OWNERS</h5>
          <h1 style={{ ...section_head, color: "black" }}>
            WHY PARTNER WITH SALONWALLAH
          </h1>
          <div className="container text-start my-5">
            <p>
              Are you a salon owner looking to expand your reach and attract a
              broader clientele? Partnering with Salon Wallah offers several
              advantages:
            </p>

            <div>
              <ul>
                <li className="custom-scissor-bullets">
                  <span style={{ color: constant.color.primary }}>
                    Increased Visibility:
                  </span>{" "}
                  Gain exposure to a larger client base. We connect you with
                  individuals actively seeking beauty and wellness services in
                  your area.
                </li>

                <li className="custom-scissor-bullets">
                  <span style={{ color: constant.color.primary }}>
                    Convenience:
                  </span>{" "}
                  Attract clients who value convenience. With our platform, you
                  can streamline the booking process and cater to busy
                  schedules.
                </li>

                <li className="custom-scissor-bullets">
                  <span style={{ color: constant.color.primary }}>
                    Marketing Support:
                  </span>{" "}
                  Benefit from our marketing efforts, including online
                  advertising and social media exposure, to boost your salon's
                  presence.
                </li>

                <li className="custom-scissor-bullets">
                  <span style={{ color: constant.color.primary }}>
                    Business Growth:
                  </span>{" "}
                  Access a steady stream of clients, helping you fill
                  appointment slots and maximize your salon's potential.
                </li>

                <li className="custom-scissor-bullets">
                  <span style={{ color: constant.color.primary }}>
                    Safety and Quality:
                  </span>{" "}
                  Join a network that prioritizes safety and quality. We enforce
                  strict hygiene and sanitation standards to ensure a safe
                  environment for your clients.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutPage;
