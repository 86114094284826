import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import constant from "../assets/constants.json";

import { useSelector } from "react-redux";

function ServicesCarousel() {
  const serviceList = useSelector((state) => state.services.value);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  return (
    <>
      <Carousel
        draggable={true}
        swipeable={true}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={1000}
        transitionDuration={1000}
        ssr={true}
      >
        {serviceList.map((service, id) => (
          <div
            className="container p-2 row"
            key={id}
            style={{
              aspectRatio: "2/3",
              overflow: "hidden",
              textTransform: "uppercase",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={service.urls[0]}
              alt={service.name}
              style={{
                height: "75%",
                objectFit: "cover",
              }}
            />

            <div
              className="d-flex flex-row justify-content-center align-items-center h-100 w-100 p-2"
              style={{
                position: "absolute",
                top: "50%",
                left: "45%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <a
                href="/contact/#contact-form"
                style={{
                  textDecoration: "none",
                  border: "none",
                  width: "100%",
                  color: "black",
                  backgroundColor: constant.color.heavy_light,
                  fontFamily: constant.font.heading.family,
                  padding: "1rem 2rem",
                  fontSize: "0.85rem",
                  letterSpacing: "4px",
                  fontWeight: "500",
                }}
                className="service_carousel_item_btn"
              >
                {service.name}
              </a>
            </div>
          </div>
        ))}
      </Carousel>
    </>
  );
}

export default ServicesCarousel;
