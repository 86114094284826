import React from "react";
import "../index.css";

export default function UnderConstruction() {
  return (
    <div
      className="under-construction-parent d-flex justify-content-center align-items-center"
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <h1 className="under-construction-text">404 Not Found</h1>
    </div>
  );
}
