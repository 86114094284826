import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const servicesSlice = createSlice({
  name: "services",
  initialState: { value: initialState },
  reducers: {
    setServicesList: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setServicesList } = servicesSlice.actions;

export default servicesSlice.reducer;
