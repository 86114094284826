import React from "react";

import { MdLocationOn, MdCall, MdEmail, MdFax, Md } from "react-icons/md";
import { BsGlobe2 } from "react-icons/bs";
import QueryForm from "../components/QueryForm";

import constant from "../assets/constants.json";
import contactImg from "../assets/contact.jpg";

const sub_head_txt_above = {
  color: constant.color.light,
  fontWeight: "400",
  fontFamily: constant.font.heading.family,
};

const section_head = {
  fontWeight: "700",
  fontFamily: constant.font.sub.family,
  margin: "0",
  marginTop: "8px",
  color: "white",
};

function ContactPage() {
  return (
    <div style={{ textAlign: "center" }}>
      <section>
        <div
          style={{
            height: "100vh",
            background: `url(${contactImg})`,
            backgroundAttachment: "fixed",
            backgroundPosition: "0 0",
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              width: "inherit",
              height: "inherit",
              backdropFilter: "brightness(70%)",
              textAlign: "right",
            }}
            className="row align-content-center"
          >
            <div className="col p-5">
              <h5
                style={{
                  ...sub_head_txt_above,
                  fontSize: "2vmax",
                }}
              >
                IT ALL STARTS WITH A CONVERSATION
              </h5>
              <h1 style={{ ...section_head, fontSize: "9vmin" }}>CONTACT US</h1>
            </div>
          </div>
        </div>
      </section>

      <section
        id="contact-links"
        className=" py-5"
        style={{ backgroundColor: constant.color.light }}
      >
        <div className="container">
          <br />
          <br />
          <div className="row g-4">
            <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
              <div className="card p-1 text-center h-100">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1751.6512149742082!2d77.35862993847994!3d28.590702693921898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce53752b419ab%3A0xb270b52bb535808a!2sVillage%20Gijhore!5e0!3m2!1sen!2sin!4v1695927053003!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
              <div className="card p-4 text-center h-100">
                <div className="row">
                  <div className="col-4 col-md-12 col-lg-12 col-xl-12 align-self-center">
                    <MdCall
                      size={"4rem"}
                      style={{
                        margin: "0 auto",
                        color: constant.color.primary,
                      }}
                    />
                  </div>
                  <div className="col-8 col-md-12 col-lg-12 col-xl-12">
                    <h5 className="my-3" style={{ fontWeight: "bold" }}>
                      PHONE NUMBER
                    </h5>
                    <p>
                      234-9876-5400
                      <br /> 888-0123-4567 (Toll Free)
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
              <a
                href="https://www.facebook.com/people/Salon-Wallah/61551934917322/?is_tour_completed=true"
                target="_blank"
              >
                <div className="card p-4 text-center h-100 text-dark">
                  <div className="row">
                    <div className="col-4 col-md-12 col-lg-12 col-xl-12 align-self-center">
                      <BsGlobe2
                        size={"4rem"}
                        style={{
                          margin: "0 auto",
                          color: constant.color.primary,
                        }}
                      />
                    </div>
                    <div className="col-8 col-md-12 col-lg-12 col-xl-12">
                      <h5 className="my-3" style={{ fontWeight: "bold" }}>
                        SOCIAL
                      </h5>
                      <p>Connect with us</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
              <div className="card p-4 text-center h-100">
                <div className="row">
                  <div className="col-4 col-md-12 col-lg-12 col-xl-12 align-self-center">
                    <MdEmail
                      size={"4rem"}
                      style={{
                        margin: "0 auto",
                        color: constant.color.primary,
                      }}
                    />
                  </div>
                  <div className="col-8 col-md-12 col-lg-12 col-xl-12">
                    <h5 className="my-3" style={{ fontWeight: "bold" }}>
                      EMAIL
                    </h5>
                    <p>hello@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </section>

      <section
        id="contact-form"
        style={{
          backgroundColor: constant.color.heavy_light,
          color: constant.color.dark,
        }}
      >
        <br />
        <br />
        <div className="container">
          <div className="row g-3">
            <div className="col-12 col-xs-12 col-md-6 col-lg-6 col-sm-12 col-xl-6">
              <QueryForm />
            </div>
            <div className="col-12 col-xs-12 col-md-6 col-lg-6 col-sm-12 col-xl-6 text-start align-items-center">
              <div className="container p-5 my-auto text-dark">
                <h1
                  style={{
                    fontSize: "3rem",
                  }}
                >
                  Get in touch
                </h1>
                <h5
                  style={{
                    fontWeight: "700",
                    lineHeight: "1.8rem",
                    marginTop: "20px",
                  }}
                >
                  <i>
                    We can ensure{" "}
                    <span style={{ color: constant.color.primary }}>
                      reliability
                    </span>
                    ,{" "}
                    <span style={{ color: constant.color.primary }}>
                      low cost fares
                    </span>{" "}
                    and most important, with{" "}
                    <span style={{ color: constant.color.primary }}>
                      safety and comfort{" "}
                    </span>
                    in mind.
                  </i>
                </h5>
                <p
                  style={{
                    lineHeight: "2rem",
                    marginTop: "20px",
                  }}
                >
                  Unlock the potential for growth and success. Contact us today
                  to explore the opportunities that await when you become a
                  Salon Wallah partner. Together, we'll make every salon visit
                  an extraordinary experience.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </section>
    </div>
  );
}

export default ContactPage;
