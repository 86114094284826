import React from "react";
// import BlogItem from "../components/BlogItem";
import "../style/BlogPage.css";

// import img1 from "../assets/sample-2.jpg";

import b1Img from "../assets/blog1.jpg";
import b2Img from "../assets/blog2.jpg";

// import blogImg1_1 from "../assets/blog-hst-1.jpg";
// import blogImg1_2 from "../assets/blog-hst-2.jpg";
// import blogImg1_3 from "../assets/blog-hst-3.webp";
// import blogImg1_4 from "../assets/blog-hst-4.jpg";

import blogBgBannerImg from "../assets/blog-bg1.jpg";

import blogItemImg1 from "../assets/home-4.webp";

import constant from "../assets/constants.json";
import { useSelector } from "react-redux";

// const bi = (
//   <div className="py-3">
//     <BlogItem title={"Hero Title"} src={img1} link={"#"} type={"img"} />
//   </div>
// );

// const blogs = [
//   {
//     img: blogItemImg1,
//     date: "Nov 14 2022",
//     title: "How to find your skin's undertones",
//     body:
//       "<p>If you’re an expert makeup artist or beauty guru, you can probably skip ahead to the next blog post for inspiration. If you don’t have time to follow the latest social media trends, aren’t sure how to match your foundation to your skin tone, or have no idea what your hair stylist means when they mention cool and warm hues; this one is for you. Read on to learn how to find your skin’s undertones, once and for all:</p>" +
//       "<h3 style='font-weight:bold;'>1. Why Do I Need to Know My Skin’s Undertones?</h3>" +
//       "<p>You have a good idea of whether your skin is light, medium, or dark in nature. This is a good starting point and can help with the basics when purchasing things like foundation. Unfortunately, however; that’s about as far as it will take you. From there, you need to be able to identify whether your skin has undertones that are cool or warm in order to choose clothing, jewelry, makeup, and hair colors that are most flattering to your unique features. This can mean the difference between looking totally washed out or brilliantly glowing.</p>" +
//       "<h3 style='font-weight:bold;'>2. Find Your Skin’s Undertones in 3 Simple Steps</h3>" +
//       "<p> 1. Go outside or stand in an area of your home that has plenty of natural light.</p>" +
//       "<p> 2. Flip your arm over and take a peek at the veins on the inside of your wrist. </p>" +
//       "<p> 3. Determine whether they appear to be blue/purple or green.</p>" +
//       "<p>Simple, right? So what does this mean?</p>" +
//       "<p>If you decided that your veins look blue or purple in natural lighting, you have cool undertones. This means that you have a skin tone that has notes of blue, pink, or red.</p>" +
//       "<p>Those who have veins that look green in natural lighting have warm undertones. This means that your complexion has yellow, golden, or peach hints.</p>" +
//       "<h3 style='font-weight:bold;'>3. Neutral Undertones</h3>" +
//       "<p>If you took a look at the inside of your wrist and can’t decide whether you see blue/purple or green, or you see a mix of both, you have neutral undertones. This means that you have a mix of the two undertones. In most cases, you may lean slightly cooler or slightly warmer. Despite this, you have a free range of all of these tones and can choose one or the other. This applies to both makeup and hair color, but you will likely have a preference for one over the other based on what appears to be more flattering to your other features.</p>" +
//       "<h3 style='font-weight:bold;'>Use Your new found Knowledge at Salon Wallah</h3>" +
//       "<p><a href='/contact'>Connect</a> with us</p>",
//   },
//   {
//     img: blogImg1_1,
//     date: "Oct 14 2022",
//     title: "Haircuts to try this fall",
//     body:
//       "<p>Much like each season inspires changes to the clothes and colors that you wear, the shift to fall can prompt a similar desire when it comes to your hair. Whether you opt to embrace the traditional fall colors with your hair color or you are looking for a new, stylish haircut in Davie, the season is full of inspiration. Uncover some of our favorite haircuts for Autumn:</p>" +
//       "<br /><h3 style='font-weight:bold;'>1. The A-Line Bob</h3>" +
//       `<div style='width: 100%; display:flex; flex-direction:row; justify-content:center;'><img src=${blogImg1_1} width='50%' alt='salon wallah style'/></div>` +
//       `<p>When it comes to bobs, you can’t go wrong with any of the variations. From short and sleek to mid-length with layers, they are stylish and easy to maintain. For the upcoming season, we can’t get enough of the classic A-Line bob. This haircut is generally kept short in the back and gradually increases in length towards the front of the face. It is highly flattering on round faces and provides a slimming effect. Likewise, it can easily be customized to fit your preferences when it comes to the overall length, layers, or the addition of bangs.</p>` +
//       `<p>Already rocking this haircut in SalonWallah and want to spice up your color for fall? Ask your hair stylist to add caramel-colored highlights or opt for a rich auburn base color to ring in the season.</p>` +
//       `<br /><h3 style='font-weight:bold;'>2. The Modern Pixie</h3>` +
//       `<div style='width: 100%; display:flex; flex-direction:row; justify-content:center;'><img src=${blogImg1_2} width='50%' alt='salon wallah style'/></div>` +
//       `<p>Pixie cuts are ultra chic and one of the simplest haircuts in Davie when it comes to upkeep. Although the classic pixie is kept at a barely-there length, modern renditions see the length slightly longer. In either case, the look is highly structured and draws attention to the facial features. Before visiting your local Davie hair salon, be sure to consider the length you are looking for. If you are looking for a longer pixie cut, it is important to let your hairstylist know what you are envisioning. When you feel that you can’t explain the hairstyle adequately, consider bringing in a photo so that everyone is on the same page.</p>` +
//       `<br /><h3 style='font-weight:bold;'>3. Layered Bangs</h3>` +
//       `<div style='width: 100%; display:flex; flex-direction:row; justify-content:center;'><img src=${blogImg1_3} width='50%' alt='salon wallah style'/></div>` +
//       `<p>Considering a change but don’t want to do anything too dramatic? Consider adding some bangs to your current haircut. In Davie, layered bangs remain a popular choice throughout the year. This is because they are a flexible option for those who typically don’t wear a style with bangs. The layers make them easily manipulated, meaning you can part them down the middle, push them to the side or keep them straight across your forehead.</p>` +
//       `<br /><h3 style='font-weight:bold;'>4. The Lob</h3>` +
//       `<div style='width: 100%; display:flex; flex-direction:row; justify-content:center;'><img src=${blogImg1_4} width='50%' alt='salon wallah style'/></div>` +
//       `<p>This style is often sported by those who are in the midst of growing out a much shorter bob, but it has become a style all its own in recent years. The “lob” (short for long bob) is the ultimate mid-length hairstyle. In most cases, it is kept simple with few layers and no bangs. It is at the perfect length where it can easily be styled and worn down or thrown up into a messy bun for a quick trip to the gym. As one of the most popular haircuts in Davie, it is a top choice moving into the fall season.</p>` +
//       `<p>Want to make a change and get one of these fall haircuts in SalonWallah? Book an <a href='/contact'>appointment</a> at SalonWallah now.</p>`,
//   },
// ];

const sub_head_txt_above = {
  color: constant.color.primary,
  fontWeight: "400",
  fontFamily: constant.font.heading.family,
};

const section_head = {
  fontWeight: "700",
  fontFamily: constant.font.sub.family,
  margin: "0",
  marginTop: "8px",
  color: "white",
};

const sub_head_txt = {
  fontWeight: "500",
  fontFamily: constant.font.sub.family,
  margin: "0",
  marginTop: "8px",
};

const style_head_hr = {
  width: "120px",
  border: "none",
  backgroundColor: constant.color.primary,
  height: "2px",
  opacity: "1",
  margin: "32px 0 0 0 ",
};

function BlogPage() {
  const blogs = useSelector((state) => state.blog.value);

  return (
    <div style={{ textAlign: "center" }}>
      <section>
        <div
          style={{
            height: "100vh",
            backgroundImage: `url(${blogItemImg1})`,
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
          }}
        >
          <div
            style={{
              width: "inherit",
              height: "inherit",
              backdropFilter: "brightness(70%)",
              textAlign: "right",
            }}
            className="row align-content-center"
          >
            <div className="col p-5">
              <h5
                style={{
                  ...sub_head_txt_above,
                  fontSize: "2vmax",
                  color: constant.color.light,
                }}
              >
                CHECK OUT WHAT'S HAPPENING IN SALON WALLAH
              </h5>
              <h1 style={{ ...section_head, fontSize: "9vmin" }}>OUR BLOGS</h1>
            </div>
          </div>
        </div>
      </section>

      <br />

      <br />

      <section id="whats_happening">
        <div className="container py-5">
          <div className="row justify-content-between gx-0 gy-2 my-5">
            <div className="col-12 col-md-3 col-lg-3 col-xl-3">
              <div
                className="img-box"
                style={{
                  backgroundImage: `url(${b1Img})`,
                  aspectRatio: "6/9",
                  transform: "translateX(-20%) translateY(-20%)",
                  width: "130%",
                }}
              ></div>
            </div>

            <div className="col-12 col-md-5 col-lg-5 col-xl-5 text-center">
              <h5 style={sub_head_txt_above}>WELCOME TO THE BLOGS</h5>
              <h2 style={sub_head_txt}>WHAT'S HAPPENING NOW</h2>
              <hr style={{ ...style_head_hr, width: "100%" }} />
              <p style={{ marginTop: "32px" }}>
                The Salon Wallah used to be greater than simply an area to get a
                hairstyle or cut; it was a center of the community where people
                came together to bond, to socialize and to exchange suggestions.
                With the goal of bringing this camaraderie back in style, Salon
                Wala, a barber store and shop with places in India. This section
                features all the latest talks and news about our company and
                what we have done till date. A place to get to know us better
                and look at our knowledge and expertise.
              </p>
              <p style={{ margin: "32px 0" }}>
                Thanks for supporting Salon Wallah!
              </p>
            </div>
            <div className="col-12 col-md-3 col-lg-3 col-xl-3">
              <div
                className="img-box"
                style={{
                  backgroundImage: `url(${b2Img})`,
                  aspectRatio: "2/3",
                  width: "130%",
                  transform: "translateY(10%)",
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>

      <br />

      <section
        style={{
          backgroundImage: `url(${blogBgBannerImg})`,
          backgroundSize: "cover",
          height: "50vmin",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          margin: "5rem 0",
        }}
      >
        <div
          style={{ height: "inherit", backdropFilter: "brightness(50%)" }}
          className="container-fluid"
        >
          <div className="row align-items-center h-100">
            <div className="col ">
              <h1
                style={{
                  ...sub_head_txt_above,
                  color: constant.color.light,
                  fontWeight: "600",
                  letterSpacing: "6px",
                }}
              >
                YOUR BEAUTY IS OUR PROFESSION
              </h1>
            </div>
          </div>
        </div>
      </section>

      <br />

      <section id="blogs">
        {blogs &&
          blogs.map(
            (item, index) =>
              item.html !== null && (
                <div className="container px-5">
                  <div className="row align-items-center">
                    <div className="col-12 col-lg-7 col-xl-7 text-start">
                      <h1
                        style={{
                          fontFamily: `${constant.font.heading.family}`,
                          fontWeight: "bold",
                        }}
                      >
                        {item.title}
                      </h1>
                    </div>

                    <div className="col-12 col-lg-5 col-xl-5 text-end">
                      <h6>{new Date(item.createdAt).toDateString()}</h6>
                    </div>
                  </div>
                  <br />
                  <img
                    src={item.coverImage}
                    width={"50%"}
                    style={{ boxShadow: "0 0 5px gray" }}
                  />
                  <br />
                  <div
                    dangerouslySetInnerHTML={{ __html: item.html }}
                    style={{
                      padding: "5%",
                      fontWeight: "600",
                      textAlign: "justify",
                    }}
                  ></div>
                </div>
              )
          )}
      </section>
    </div>
  );
}

export default BlogPage;
