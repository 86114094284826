import { configureStore } from "@reduxjs/toolkit";

import servicesReducer from "./features/servicesSlice";
import tokenReducer from "./features/tokenSlice";
import blogReducer from "./features/blogSlice";
import galleryReducer from "./features/gallerySlice";
import testimonialReducer from "./features/testimonialSlice";

export const store = configureStore({
  reducer: {
    services: servicesReducer,
    tokens: tokenReducer,
    blog: blogReducer,
    gallery: galleryReducer,
    testimonial: testimonialReducer,
  },
});
