import React from "react";

import cnt from "../assets/constants.json";
import logo from "../assets/sw-brand-logo.png";

function BenefitsPage() {
  const headingStyle = { fontWeight: "bold", textAlign: "start" };

  return (
    <div
      style={{
        marginTop: "3.5rem",
        backgroundColor: cnt.color.light,
        fontFamily: cnt.font.heading.family,
        color: cnt.color.dark,
      }}
    >
      <section className="container py-md-5">
        <div className="row justify-content-center">
          <div
            className="container row align-items-center text-white"
            style={{
              padding: "calc(1.8rem + .5vw)",
              backgroundColor: cnt.color.primary,
            }}
          >
            <div className="d-none d-md-block col-md-3 col-lg-2 justify-content-center">
              <div
                style={{
                  height: "100%",
                  aspectRatio: "1/1",
                  borderRadius: "10px",
                  overflow: "hidden",
                  objectPosition: "center",
                  boxShadow: "0 2px 2px 1px #b7b7b733",
                }}
              >
                <img
                  src={logo}
                  style={{
                    height: "100%",
                    objectFit: "contain",
                    transform: "scale(1.6) translateY(18%)",
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-md-9 col-lg-10">
              <h1
                style={{
                  //fontSize: "calc(0.5rem + 2vw)",
                  margin: 0,
                  fontWeight: "bold",
                  textAlign: "start",
                }}
                is="h2"
              >
                Benefits
              </h1>
            </div>
          </div>
        </div>

        <article
          className="container text-start my-5"
          style={{ textAlign: "justify" }}
        >
          <h2 style={headingStyle}>Benefits of Choosing Us</h2>
          <br />
          <p>
            Welcome to Salon Wallah, your gateway to effortless beauty and
            wellness services. In today's fast-paced world, convenience and
            efficiency have become paramount, and that's precisely what our
            beauty services appointment booking app offers. Discover the
            numerous advantages of using Salon Wallah to book your next salon,
            parlour, or spa appointment.
          </p>

          <br />

          <h4 style={headingStyle}>1. Effortless Booking</h4>
          <br />

          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              Gone are the days of waiting in salons for your turn.{" "}
            </span>
            With Salon Wallah, you can browse and book appointments with just a
            few taps on your smartphone. Our user-friendly interface allows you
            to quickly find the services you need and reserve a time that fits
            your schedule.
          </p>

          <br />

          <h4 style={headingStyle}>2. Time-Saving</h4>
          <br />
          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              Your time is precious, and we respect that.{" "}
            </span>
            Salon Wallah is here to respect and optimize it, catering to the
            unique needs of both private sector employees in bustling corporate
            offices and dedicated government sector workers. Our app eliminates
            the need for time-consuming trips to the salon for booking, ensuring
            that busy professionals can enjoy efficient scheduling. Whether
            you're on a tight lunch break, commuting to and from work, or simply
            unwinding at home after a long day, you can secure your appointment
            in seconds. Say goodbye to those waiting rooms and hello to more
            "me" time, giving you the flexibility to balance your work
            commitments and personal care seamlessly. Salon Wallah is your
            gateway to effortless beauty and wellness services, ensuring that
            you have the time to invest in yourself, regardless of your
            profession.
          </p>
          <br />

          <h4 style={headingStyle}>3. 24/7 Accessibility</h4>
          <br />
          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              Salon Wallah never sleeps.{" "}
            </span>
            Our app is accessible 24/7, giving you the freedom to book
            appointments whenever it suits you best. Whether it's early in the
            morning or late at night, our platform is always ready to assist
            you.
          </p>
          <br />

          <h4 style={headingStyle}>4. Extensive Salon Network</h4>
          <br />
          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              We've partnered with top-quality salons, parlours, and spas in
              your area.{" "}
            </span>
            Salon Wallah has established partnerships with a diverse range of
            top-quality salons, parlors, and spas at your nearby locations. Our
            extensive network ensures that you have a multitude of options to
            choose from, all within your reach. Whether you're in search of a
            trendy haircut, a rejuvenating massage, a luxurious spa experience,
            or a quick, revitalizing manicure, Salon Wallah has you covered. Our
            broad and ever-expanding selection of trusted beauty and wellness
            providers in your vicinity means that you can easily find the
            perfect salon that suits your preferences and needs. From vibrant
            city centers to serene suburban neighborhoods, Salon Wallah brings
            the best in beauty and relaxation to your doorstep, making it easier
            than ever to discover the ideal place to pamper yourself. With our
            extensive salon network, you're not limited to just one choice; you
            have a world of beauty options right at your fingertips.
          </p>
          <br />

          <h4 style={headingStyle}>5. Transparency</h4>
          <br />
          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              We believe in complete transparency.{" "}
            </span>
            Our app provides detailed information about each salon, including
            services offered, pricing, reviews, and ratings. You can make
            informed decisions and select a salon that aligns with your
            preferences and budget.
          </p>

          <br />

          <h4 style={headingStyle}>6. Customized Experience</h4>
          <br />
          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              Your beauty needs are unique, and so are your preferences.{" "}
            </span>
            Salon Wallah allows you to customize your beauty experience. Filter
            salons by location, service type, price range, and more. Find the
            ideal salon that caters to your specific requirements.
          </p>

          <br />

          <h4 style={headingStyle}>7. Appointment Reminders</h4>
          <br />
          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              Never miss an appointment again.{" "}
            </span>
            Salon Wallah sends you reminders and notifications to ensure you're
            always on top of your beauty schedule. Say goodbye to forgotten
            appointments and hello to punctuality.
          </p>

          <br />

          <h4 style={headingStyle}>8. Safe and Secure Payments</h4>
          <br />
          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              Your financial information is safe with us.{" "}
            </span>
            We use secure payment processing to protect your payment card
            details. Salon Wallah ensures the privacy and security of your
            financial data.
          </p>

          <br />

          <h4 style={headingStyle}>9. Review and Rating System</h4>
          <br />
          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              Join a community of informed beauty enthusiasts.{" "}
            </span>
            Our app features a review and rating system that allows you to read
            feedback from other users. Leave your own reviews to share your
            experiences and help others make informed decisions.
          </p>

          <br />

          <h4 style={headingStyle}>10. Rewarding Loyalty Program</h4>
          <br />
          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              We value your loyalty.{" "}
            </span>
            Salon Wallah offers a loyalty program that rewards you for booking
            services through our platform regularly. Earn points, discounts, and
            exclusive offers as a token of our appreciation.
          </p>

          <br />

          <h4 style={headingStyle}>11. Environmental Responsibility</h4>
          <br />
          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              Salon Wallah is committed to sustainability.{" "}
            </span>
            By using our app, you contribute to reducing paper waste associated
            with traditional appointment booking systems. We're dedicated to
            doing our part for the environment.
          </p>

          <br />

          <h4 style={headingStyle}>12. At-Home Services</h4>
          <br />
          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              The salon comes to you.{" "}
            </span>
            In addition to booking appointments at salons, parlors or spa… Salon
            Wallah offers at-home services. Enjoy the ultimate convenience of
            beauty treatments in the comfort of your own space.
          </p>

          <br />

          <h4 style={headingStyle}>13. Empowering Beauty Professionals</h4>
          <br />
          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              We support beauty professionals and businesses.{" "}
            </span>
            Salon Wallah provides a platform for salons, parlour, spa and beauty
            experts to expand their reach and connect with clients actively
            seeking their services. Partner with us to grow your business.
          </p>

          <br />

          <h4 style={headingStyle}>14. Instant Updates</h4>
          <br />
          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              Stay informed with real-time updates.{" "}
            </span>
            Our app provides instant updates on appointment confirmations,
            changes, and cancellations. You're always in the know about your
            beauty appointments.
          </p>

          <br />

          <h4 style={headingStyle}>15. Health and Safety</h4>
          <br />
          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              Your health and safety matter.{" "}
            </span>
            Salon Wallah enforces strict hygiene and safety standards for all
            partner salons and professionals. You can enjoy your beauty
            treatments with peace of mind.
          </p>

          <br />

          <h4 style={headingStyle}>16. Flexibility</h4>
          <br />
          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              Life can be unpredictable.{" "}
            </span>
            If your plans change, Salon Wallah allows you to reschedule or
            cancel appointments through the app. We understand that flexibility
            is key.
          </p>

          <br />

          <h4 style={headingStyle}>17. Community</h4>
          <br />
          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              Join a community that values self-care.{" "}
            </span>
            Salon Wallah brings together beauty enthusiasts who understand the
            importance of self-care and personal grooming. Connect with
            like-minded individuals.
          </p>

          <br />

          <h4 style={headingStyle}>18. Innovation and Convenience</h4>
          <br />
          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              We're at the forefront of innovation.{" "}
            </span>
            Salon Wallah represents the future of beauty services. Embrace the
            convenience and efficiency of our app to enhance your self-care
            routine.
          </p>

          <br />

          <h4 style={headingStyle}>19. Support</h4>
          <br />
          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              We're here for you.{" "}
            </span>
            If you have any questions, concerns, or issues, our customer support
            team is ready to assist you. Your satisfaction is our priority.
          </p>

          <br />

          <h4 style={headingStyle}>20. Your Path to Beauty</h4>
          <br />
          <p>
            <span style={{ color: cnt.color.primary, fontWeight: "bold" }}>
              Salon Wallah is your partner in beauty and wellness.{" "}
            </span>
            We're here to simplify your life and enhance your self-care journey.
            Discover the benefits of our salon appointment booking app today.
          </p>

          <br />

          <p>
            Join Salon Wallah and experience the future of beauty and wellness
            services at your fingertips. Your path to beauty, relaxation, and
            self-care begins <a href="./">here</a>.
          </p>

          <h6 style={headingStyle}>Last Updated: October 15, 2023</h6>
          <br />
        </article>
      </section>
    </div>
  );
}

export default BenefitsPage;
