import React from "react";

import cnt from "../assets/constants.json";
import logo from "../assets/sw-brand-logo.png";

function PrivacyPage() {
  const headingStyle = { fontWeight: "bold", textAlign: "start" };

  return (
    <div
      style={{
        marginTop: "3.5rem",
        backgroundColor: cnt.color.light,
        fontFamily: cnt.font.heading.family,
        color: cnt.color.dark,
      }}
    >
      <section className="container py-md-5">
        <div className="row justify-content-center">
          <div
            className="container row align-items-center text-white"
            style={{
              padding: "calc(1.8rem + .5vw)",
              backgroundColor: cnt.color.primary,
            }}
          >
            <div className="d-none d-md-block col-md-3 col-lg-2 justify-content-center">
              <div
                style={{
                  height: "100%",
                  aspectRatio: "1/1",
                  borderRadius: "10px",
                  overflow: "hidden",
                  objectPosition: "center",
                  boxShadow: "0 2px 2px 1px #b7b7b733",
                }}
              >
                <img
                  src={logo}
                  style={{
                    height: "100%",
                    objectFit: "contain",
                    transform: "scale(1.6) translateY(18%)",
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-md-9 col-lg-10">
              <h1
                style={{
                  //fontSize: "calc(0.5rem + 2vw)",
                  margin: 0,
                  fontWeight: "bold",
                  textAlign: "start",
                }}
                is="h2"
              >
                Privacy Policy
              </h1>
            </div>
          </div>
        </div>

        <article
          className="container text-start my-5"
          style={{ textAlign: "justify" }}
        >
          <h2 style={headingStyle}>Privacy Policy</h2>
          <br />
          <p>
            Welcome to Salon Wallah, a platform dedicated to making beauty and
            wellness services easily accessible while safeguarding your privacy
            and personal information. This Privacy Policy outlines how we
            collect, use, disclose, and protect your data when you use our
            website or mobile application (collectively, "Services"). By
            accessing or using our Services, you consent to the practices
            described in this Privacy Policy.
          </p>

          <br />

          <h4 style={headingStyle}>1. Information We Collect</h4>
          <br />
          <h5 style={headingStyle}>1.1 Personal Information</h5>
          <p>
            We collect various types of information that you provide directly or
            that we gather automatically when you use our Services. This may
            include:
          </p>
          <ul>
            <li>
              <b>Contact Information:</b> This includes your name, email
              address, phone number, and postal address. We use this information
              to communicate with you, process bookings, and provide updates on
              your appointments.
            </li>
            <li>
              <b>Payment Information:</b> To facilitate bookings and payments,
              we may collect your payment card details, billing address, and
              other related information. Rest assured, we take security
              seriously, and we employ industry-standard encryption and security
              protocols to protect your financial data.
            </li>
          </ul>
          <h5 style={headingStyle}>1.2 Location Information</h5>
          <p>
            To provide location-based services such as helping you find nearby
            salons and professionals, we collect your location information when
            you use our mobile application. You can control location services
            through your device settings or app permissions.
          </p>
          <h5 style={headingStyle}>1.3 Transaction Data</h5>
          <p>
            We collect information about your bookings, payments, and
            interactions with service providers. This includes details such as
            appointment dates, times, services requested, and transaction
            amounts. This data helps us manage your bookings efficiently and
            improve our Services.
          </p>
          <h5 style={headingStyle}>1.4 Device and Usage Information</h5>
          <p>
            When you access our Services, we automatically collect certain
            device and usage information. This may include your IP address,
            device type, operating system, browser type, and other technical
            information. We use this data to enhance your experience,
            troubleshoot issues, and analyze usage patterns.
          </p>
          <h5 style={headingStyle}>1.5 Cookies and Tracking Technologies</h5>
          <p>
            We use cookies and similar tracking technologies to collect
            information about your browsing and usage patterns on our website.
            Cookies are small text files that are stored on your device and help
            us recognize you on future visits. You can manage cookie preferences
            through your browser settings.
          </p>

          <br />

          <h4 style={headingStyle}>2. How We Use Your Information</h4>
          <br />
          <p>
            We use the information we collect for various purposes, including
            but not limited to:
          </p>
          <br />
          <h5 style={headingStyle}>2.1 Providing and Improving Our Services</h5>
          <p>
            We use your data to deliver the Services you request, manage
            appointments, process payments, and enhance your overall experience.
            This includes tailoring our Services to your preferences and needs.
          </p>
          <h5 style={headingStyle}>2.2 Communication</h5>
          <p>
            We use your contact information to communicate with you regarding
            your bookings, appointments, and updates related to our Services.
            This may include email confirmations, reminders, and important
            service-related announcements.
          </p>
          <h5 style={headingStyle}>2.3 Personalization</h5>
          <p>
            We may use your data to offer personalized content, recommendations,
            and special offers. This helps us tailor our Services to your
            individual preferences and interests.
          </p>
          <h5 style={headingStyle}>2.4 Security and Integrity</h5>
          <p>
            Ensuring the security and integrity of our platform is a top
            priority. We use your information to detect and prevent fraud,
            unauthorized access, and other potentially harmful activities.
          </p>
          <h5 style={headingStyle}>2.5 Analytics and Insights</h5>
          <p>
            We use device and usage information, along with data from cookies
            and tracking technologies, to analyze user behavior, improve our
            Services, and optimize our marketing efforts.
          </p>

          <br />

          <h4 style={headingStyle}>3. How We Share Your Information</h4>
          <br />
          <p>
            We may share your information with various parties for the purposes
            described in this Privacy Policy. These parties may include:
          </p>
          <br />
          <h5 style={headingStyle}>3.1 Service Providers</h5>
          <p>
            We may share your data with service providers who assist us in
            delivering the Services, processing payments, and maintaining the
            functionality of our platform. These providers are contractually
            obligated to protect your information and use it only for the
            purposes we specify.
          </p>
          <h5 style={headingStyle}>3.2 Affiliates and Business Partners</h5>
          <p>
            We may share your data with our affiliates and business partners for
            marketing and operational purposes. However, we do not sell your
            personal information to third parties.
          </p>
          <h5 style={headingStyle}>3.3 Legal Obligations</h5>
          <p>
            We may disclose your information to comply with legal requirements,
            such as responding to subpoenas, court orders, or government
            requests. We may also share your data when we believe it is
            necessary to protect our rights, safety, or the rights and safety of
            others.
          </p>
          <h5 style={headingStyle}>
            3.4 Third-Party Analytics and Advertising Providers
          </h5>
          <p>
            We may use third-party analytics and advertising providers to help
            us understand user behavior and improve our Services. These
            providers may collect and process information about your device and
            usage patterns.
          </p>

          <br />

          <h4 style={headingStyle}>4. Your Choices and Control</h4>
          <br />
          <h5 style={headingStyle}>4.1 Access and Correction</h5>
          <p>
            You have the right to access, correct, or update your personal
            information. You can do this by logging into your Salon Wallah
            account and editing your profile.
          </p>
          <h5 style={headingStyle}>4.2 Data Portability</h5>
          <p>
            You can request a copy of your data in a structured,
            machine-readable format. This allows you to transfer your
            information to another service if you wish.
          </p>
          <h5 style={headingStyle}>4.3 Object to Processing</h5>
          <p>
            You have the right to object to certain types of data processing,
            such as direct marketing. You can manage your communication
            preferences through your account settings.
          </p>
          <h5 style={headingStyle}>4.4 Withdraw Consent</h5>
          <p>
            If you have provided consent for specific data processing
            activities, you can withdraw that consent at any time. This will not
            affect the legality of any processing that occurred before your
            withdrawal.
          </p>
          <h5 style={headingStyle}>
            4.5 Opt-Out of Promotional Communications
          </h5>
          <p>
            You can opt out of receiving promotional emails and other marketing
            communications by following the unsubscribe instructions provided in
            the communication or by adjusting your communication preferences in
            your account settings.
          </p>

          <br />

          <h4 style={headingStyle}>5. Data Security</h4>
          <br />
          <p>
            We take data security seriously and have implemented security
            measures to protect your information from unauthorized access,
            disclosure, alteration, and destruction. However, no data
            transmission over the internet is entirely secure, and we cannot
            guarantee absolute security.
          </p>

          <br />

          <h4 style={headingStyle}>6. Changes to this Privacy Policy</h4>
          <br />
          <p>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or for other operational, legal, or
            regulatory reasons. When we make changes, we will revise the
            "Effective Date" at the top of this page. We encourage you to review
            this Privacy Policy periodically to stay informed about how we
            handle your data.
          </p>

          <br />

          <h4 style={headingStyle}>7. Contact Us</h4>
          <br />
          <p>
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or your personal data, please contact us at [Insert
            Contact Information]. By using Salon Wallah's Services, you
            acknowledge that you have read and understood this Privacy Policy
            and agree to the collection, use, and disclosure of your personal
            information as described herein.
          </p>

          <br />

          <h6 style={headingStyle}>Last Updated: October 15, 2023</h6>
          <br />
        </article>
      </section>
    </div>
  );
}

export default PrivacyPage;
